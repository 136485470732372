import React from "react";
import clsx from "clsx";

import { default as MuiButton } from "@material-ui/core/Button";
import BarLoader from "react-spinners/BarLoader";

const Button = ({
  label,
  color = "primary",
  onClick,
  validate = null,
  isLoading = false,
  disabled,
  size = "medium",
  danger,
  fullWidth,
  type = "button"
}) => (
  <MuiButton
    variant="contained"
    color={color}
    onClick={onClick}
    disabled={disabled || (validate === null && !isLoading ? false : true)}
    size={size}
    classes={{ root: clsx(danger && "danger-btn") }}
    fullWidth={fullWidth}
    type={type}
  >
    {isLoading ? <BarLoader width={55} color={"#ffffff"} loading={isLoading} /> : label}
  </MuiButton>
);

export default Button;
