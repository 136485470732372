import React, { useState, useEffect, useReducer } from "react";
import http from "../../../services/httpService";
import { apiEndpoint } from "../../../services/config";
import useDelete from "../../../hooks/delete";

import Header from "../../commons/Header";
import PageContent from "../../commons/PageContent";
import ActionBar from "../../commons/ActionBar";
import Table from "../../commons/table/Table";
import NoSiteWarning from "../../commons/NoSiteWarning";
import Button from "../../commons/button/Button";
import CompanyDialog from "./CompanyDialog/CompanyDialog";
import Grid from "@material-ui/core/Grid";

const companiesReducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.companies;
    case "ADD":
      return [...state, action.company];
    case "REMOVE":
      return state.filter(company => company.id !== action.id);
    default:
      throw new Error("Invalid action type.");
  }
};

const companyDialogReducer = (state, action) => {
  switch (action.type) {
    case "OPEN":
      return { isOpen: true, mode: action.mode, company: action.company ? action.company : {} };
    case "CLOSE":
      return initialCompanyDialog;
    default:
      throw new Error("Invalid action type.");
  }
};

const initialCompanyDialog = { isOpen: false, mode: "CREATE", company: {} };

const Company = () => {
  const [companies, dispatch] = useReducer(companiesReducer, []);
  const [sites, setSites] = useState([]);
  const [companyDialog, dispatchDialog] = useReducer(companyDialogReducer, initialCompanyDialog);
  const { handleDelete, renderDeleteDialog } = useDelete({
    api: `${apiEndpoint}/v1/outlet/company/`,
    callback: handleDeleteCompany
  });

  const getCompanies = () => http.get(`${apiEndpoint}/v1/outlet/company/`);
  const getSites = () => http.get(`${apiEndpoint}/v1/outlet/`);

  useEffect(() => {
    let isCancelled = false;
    http.all([getCompanies(), getSites()]).then(
      http.spread(({ data: companies }, { data: sites }) => {
        if (!isCancelled) {
          dispatch({ type: "SET", companies });
          setSites(sites);
        }
      })
    );
    return () => (isCancelled = true);
  }, []);

  const columns = [
    {
      key: "color",
      className: "color-column",
      content: ({ color }) => <div className="color" style={{ backgroundColor: color }} />
    },
    { path: "name", label: "Name" },
    {
      label: "Site(s)",
      key: "site",
      content: company => (getSitesLength(company.id) > 0 ? getSitesLength(company.id) : <NoSiteWarning />)
    },
    {
      key: "actions",
      className: "actions-column",
      content: company => (
        <React.Fragment>
          <Button
            label="Edit"
            color="secondary"
            size="small"
            onClick={() => dispatchDialog({ type: "OPEN", mode: "EDIT", company })}
          />
          <Button label="Delete" size="small" danger onClick={() => handleDelete(company.id)} />
        </React.Fragment>
      )
    }
  ];

  const getSitesLength = companyId => sites.filter(site => site.company === companyId).length;

  function handleAddCompany(company) {
    dispatch({ type: "ADD", company });
  }

  function handleEditCompany(data) {
    let newCompanies = [...companies];
    newCompanies.forEach((company, index) => {
      if (company.id === data.id) newCompanies[index] = data;
    });
    dispatch({ type: "SET", companies: newCompanies });
  }

  function handleDeleteCompany(id) {
    dispatch({ type: "REMOVE", id });
  }

  return (
    <React.Fragment>
      <Header title="Company" />
      <PageContent>
        <ActionBar>
          <Button label="Add" onClick={() => dispatchDialog({ type: "OPEN", mode: "CREATE" })} />
        </ActionBar>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Table title="Companies" columns={columns} data={companies} />
          </Grid>
        </Grid>
      </PageContent>
      <CompanyDialog
        isOpen={companyDialog.isOpen}
        onClose={() => dispatchDialog({ type: "CLOSE" })}
        mode={companyDialog.mode}
        company={companyDialog.company}
        onAddCompany={handleAddCompany}
        onEditCompany={handleEditCompany}
      />
      {renderDeleteDialog()}
    </React.Fragment>
  );
};

export default Company;
