import React from "react";
import _ from "lodash";
import Skeleton from "@material-ui/lab/Skeleton";

const TableBody = ({ data, columns }) => {
  const renderCell = (item, column) => {
    if (column.content) return column.content(item);
    return _.get(item, column.path);
  };

  const createKey = (item, column) => item.uuid + (column.path || column.key);

  return (
    <tbody>
      {_.size(data) > 0 ? (
        data.map(item => (
          <tr key={item.uuid || item.id}>
            {columns.map(column => (
              <td key={createKey(item, column)} className={column.className}>
                {renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))
      ) : (
        <tr>
          {columns.map((column, index) => (
            <td key={index}>
              <Skeleton variant="rect" width="100%" height={25} />
            </td>
          ))}
        </tr>
      )}
    </tbody>
  );
};

export default TableBody;
