import React from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const Table = ({ title, columns, data, isLoading }) => (
  <div className="table">
    <div className="table__header">{title}</div>
    <div className="table__content">
      <table>
        <TableHeader columns={columns} />
        <TableBody columns={columns} data={data} isLoading={isLoading} />
      </table>
    </div>
    <div className="table__footer"></div>
  </div>
);

export default Table;
