import React, { useState, useEffect } from "react";
import http from "../../services/httpService";
import { apiEndpoint } from "../../services/config";
import moment from "moment";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { Calendar as ReactBigCalendar, momentLocalizer } from "react-big-calendar";
import PropagateLoader from "react-spinners/PropagateLoader";

const localizer = momentLocalizer(moment);

const CustomToolbar = toolbar => {
  const goToBack = () => toolbar.onNavigate("PREV");

  const goToNext = () => toolbar.onNavigate("NEXT");

  const goToCurrent = () => toolbar.onNavigate("TODAY");

  const getLabel = () => {
    const date = moment(toolbar.date);
    return (
      <div className="label-date">
        <span className="month">{date.format("MMMM")}&nbsp;</span>
        {date.format("YYYY")}
      </div>
    );
  };

  return (
    <div className="toolbar-wrapper">
      {getLabel()}
      <div className="back-next-buttons">
        <ButtonGroup>
          <Button onClick={goToBack}>Back</Button>
          <Button onClick={goToCurrent}>Today</Button>
          <Button onClick={goToNext}>Next</Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

const Calendar = ({ events, onSelectSlot, onSelectEvent, onNavigate, isLoading }) => {
  const [companyColors, setCompanyColors] = useState({});

  useEffect(() => {
    let isCancelled = false;
    http.get(`${apiEndpoint}/v1/outlet/company/`).then(({ data }) => {
      if (!isCancelled) {
        let newData = {};
        data.forEach(company => (newData[company.name] = company.color));
        setCompanyColors(newData);
      }
    });
    return () => (isCancelled = true);
  }, []);

  const eventStyleGetter = event => {
    let company = event.company.name;
    return { style: { backgroundColor: companyColors[company] } };
  };

  return (
    <div className="calendar">
      {isLoading && (
        <div className="overlay">
          <PropagateLoader color={"#8ed200"} />
        </div>
      )}
      <ReactBigCalendar
        localizer={localizer}
        events={events}
        views={["month"]}
        startAccessor="start"
        endAccessor="end"
        popup
        selectable
        onSelectSlot={onSelectSlot}
        onSelectEvent={onSelectEvent}
        onNavigate={onNavigate}
        components={{ toolbar: CustomToolbar }}
        eventPropGetter={eventStyleGetter}
      />
    </div>
  );
};

export default Calendar;
