import React from "react";
import FilterBarInput from "./FilterBarInput";

import MomentUtils from "@date-io/moment";
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ButtonBase from "@material-ui/core/ButtonBase";

const DatePickerField = ({ name, value, onClick, onClear }) => (
  <div className="datepicker-field">
    <ButtonBase classes={{ root: "datepicker-input" }} onClick={onClick}>
      {value}
    </ButtonBase>
    <div className="clear-btn" onClick={() => onClear(name)}>
      &times;
    </div>
  </div>
);

const DatePicker = ({ label, name, value, onChange, onClear }) => (
  <FilterBarInput label={label}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MuiDatePicker
        value={value}
        onChange={onChange(name)}
        TextFieldComponent={e => <DatePickerField value={value} name={name} onClick={e.onClick} onClear={onClear} />}
        fullWidth
      />
    </MuiPickersUtilsProvider>
  </FilterBarInput>
);

export default DatePicker;
