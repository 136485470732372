import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.patch["Content-Type"] = "application/json";
axios.defaults.headers.delete["Content-Type"] = "application/json";

axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response && error.response.status >= 400 && error.response.status < 500;

  if (error.response === 401) toast("Incorrect email or password.", { type: "error" });

  if (!expectedError) toast("Something went wrong.", { type: "error" });

  return Promise.reject(error);
});

function setToken(token) {
  if (token) axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  else return;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  create: axios.create,
  all: axios.all,
  spread: axios.spread,
  setToken
};
