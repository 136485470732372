import React from "react";
import { default as MuiSelect } from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import FilterBarInput from "./FilterBarInput";

const Multiselect = ({
  label,
  name,
  value,
  options,
  onChange,
  onDeleteChip,
  indicator = "id",
  optionLabel = "name"
}) => {
  const renderChips = (name, selectedOptions, options, label, indicator) =>
    selectedOptions.length > 0 ? (
      <Grid container spacing={1}>
        {selectedOptions.map(selected => {
          let item = options.find(option => option[indicator] === selected);
          return (
            <Grid key={item[indicator]} item>
              <Chip size="small" label={item[label]} onDelete={() => onDeleteChip(name, item[indicator])} />
            </Grid>
          );
        })}
      </Grid>
    ) : (
      <em>Show All</em>
    );

  return (
    <FilterBarInput label={label}>
      <MuiSelect
        name={name}
        value={value}
        onChange={onChange}
        renderValue={selectedOptions => renderChips(name, selectedOptions, options, "name", "id")}
        multiple
        displayEmpty
        input={<InputBase />}
        classes={{ select: "select-control" }}
      >
        {options &&
          options.map(option => (
            <MenuItem key={option[indicator]} value={option[indicator]}>
              {option[optionLabel]}
            </MenuItem>
          ))}
      </MuiSelect>
    </FilterBarInput>
  );
};

export default Multiselect;
