import React, { useReducer, useState } from "react";
import http from "../../services/httpService";
import { apiEndpoint } from "../../services/config";
import getDistance from "geolib/es/getDistance";
import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";

import Dialog from "../commons/dialog/Dialog";
import DialogHeader from "../commons/dialog/DialogHeader";
import DialogContent from "../commons/dialog/DialogContent";
import DialogActions from "../commons/dialog/DialogActions";
import Detail from "../commons/Detail";
import Button from "../commons/button/Button";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

const clockReducer = (state, { type, location, distance, isNearLocation }) => {
  switch (type) {
    case "SET":
      return { location, distance, isNearLocation };
    default:
      throw new Error("Invalid action type.");
  }
};

const ClockingDialog = ({ isOpen, onClose, action, siteService, onUpdateSiteService }) => {
  const [clock, dispatch] = useReducer(clockReducer, {
    location: "",
    distance: "",
    isNearLocation: false
  });
  const [isLoading, setIsLoading] = useState(false);

  const getStatus = () => {
    switch (action) {
      case "IN":
        return { status: 0, actionName: "clock_in_location" };
      case "OUT":
        return { status: 1, actionName: "clock_out_location" };
      default:
        throw new Error("Invalid action");
    }
  };

  const handleSettings = () => {
    if (navigator.geolocation) navigator.geolocation.getCurrentPosition(success, error);
  };

  const success = position => {
    const { latitude, longitude } = position.coords;
    const siteLocation = siteService.outlet.lat_long.split(",");
    const distance = getDistance({ latitude: siteLocation[0], longitude: siteLocation[1] }, { latitude, longitude });
    dispatch({
      type: "SET",
      location: `${latitude},${longitude}`,
      distance,
      isNearLocation: distance < 500 ? true : false
    });
  };

  const error = () => toast("Please enable location service to clock in.", { type: "error" });

  const handleClocking = () => {
    setIsLoading(true);
    const { status, actionName } = getStatus();
    const formData = {
      outlet: siteService.outlet.id,
      status: status,
      [`${actionName}`]: clock.location,
      outlet_service: siteService.id
    };
    http.post(`${apiEndpoint}/v1/attendance/`, JSON.stringify(formData)).then(() => {
      onClose();
      onUpdateSiteService(siteService, status);
      toast(`Clocked ${status === 0 ? "in" : "out"} successfully.`, {
        type: "success"
      });
      setIsLoading(false);
    });
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} onEnter={handleSettings} size="xs">
      <DialogHeader>
        Clock {action === "IN" ? "In" : "Out"}
        <br />
        {_.isEmpty(siteService) ? <Skeleton variant="text" /> : siteService.outlet.name}
      </DialogHeader>
      <DialogContent>
        {!clock.isNearLocation && (
          <div className="error">Please be within 500m from the site to clock {action === "IN" ? "in" : "out"}.</div>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Detail label="Site">
              {_.isEmpty(siteService) ? <Skeleton variant="text" /> : siteService.outlet.name}
            </Detail>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Detail label="Time">{moment().format("LT")}</Detail>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Detail label="Distance(M)">{clock.distance}</Detail>
          </Grid>
          <Grid item xs={12}>
            <Detail label="Your Coordinates">{clock.location}</Detail>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button label="Confirm" onClick={handleClocking} isLoading={isLoading} disabled={!clock.isNearLocation} />
      </DialogActions>
    </Dialog>
  );
};

export default ClockingDialog;
