import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import AuthContextProvider from "./context/auth-context";

import App from "./containers/App";
import Login from "./components/Login";
import Logout from "./components/Logout";

ReactDOM.render(
  <BrowserRouter>
    <AuthContextProvider>
      <Switch>
        <Route exact path="/Login" component={Login} />
        <Route exact path="/Logout" component={Logout} />
        <Route path="/" component={App} />
      </Switch>
    </AuthContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
