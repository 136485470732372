import React, { useState } from "react";
import useForm from "../../hooks/form";
import useSelectOptions from "../../hooks/selectOptions";
import http from "../../services/httpService";
import { apiEndpoint } from "../../services/config";
import Joi from "joi-browser";
import moment from "moment";
import download from "downloadjs";

import Dialog from "../commons/dialog/Dialog";
import DialogHeader from "../commons/dialog/DialogHeader";
import DialogContent from "../commons/dialog/DialogContent";
import DialogActions from "../commons/dialog/DialogActions";
import DatePicker from "../commons/input/DatePicker";
import MultiSelect from "../commons/input/MultiSelect";
import Button from "../commons/button/Button";
import OutlinedButton from "../commons/button/OutlinedButton";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const initialData = {
  month: moment().format(),
  from_month: moment().format(),
  to_month: moment()
    .add(1, "months")
    .format(),
  employee: []
};

const schema_tab0 = {
  month: Joi.required(),
  from_month: Joi.date().required(),
  to_month: Joi.date()
    .greater(Joi.ref("from_month"))
    .required(),
  employee: Joi.optional()
};

const schema_tab1 = {
  ...schema_tab0,
  employee: Joi.array()
    .min(1)
    .required()
};

const TabPanelsWrapper = ({ children }) => <div className="tabs-panels-wrapper">{children}</div>;

const TabPanel = ({ children, value, index }) => (
  <Typography component="div" hidden={value !== index}>
    <div>{children}</div>
  </Typography>
);

const ExportDialog = ({ isOpen, onClose }) => {
  const [tab, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { data, handleDateChange, handleSelectChange, handleDeleteChip, resetData, validate } = useForm(
    initialData,
    tab === 0 ? schema_tab0 : schema_tab1
  );
  const { selectOptions, setSelectOptions, getOptionsBy } = useSelectOptions();

  const getSelectOptions = () => getOptionsBy(["EMPLOYEE"]).then(options => setSelectOptions(options));

  const handleTabChange = (e, tabIndex) => {
    setTabIndex(tabIndex);
    resetData();
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const config = { onDownloadProgress: () => setIsLoading(false), responseType: "blob" };
    if (tab === 0) {
      const month = moment(data.month).format("M");
      const year = moment(data.month).format("YYYY");
      http
        .get(`${apiEndpoint}/v1/outlet_service/export_services_to_xlsx/?month=${month}&&year=${year}`, config)
        .then(({ data }) => download(data));
    } else if (tab === 1) {
      const formData = {
        from_date: moment(data.from_month).format("DD/MM/YYYY"),
        to_date: moment(data.to_month).format("DD/MM/YYYY"),
        employees: data.employee
      };
      http
        .post(`${apiEndpoint}/v1/attendance/report/`, JSON.stringify(formData), config)
        .then(({ data }) => download(data));
    }
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} onEnter={getSelectOptions} onExited={resetData} size="xs">
      <DialogHeader>Export Records</DialogHeader>
      <DialogContent>
        <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
          <Tab label="Monthly Report" id="tab-0" />
          <Tab label="Employee(s) Report" id="tab-1" />
        </Tabs>
        <TabPanelsWrapper>
          <TabPanel value={tab} index={0}>
            <DatePicker
              label="Year and Month"
              name="month"
              value={data["month"]}
              views={["year", "month"]}
              onChange={handleDateChange}
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <DatePicker label="From" name="from_month" value={data["from_month"]} onChange={handleDateChange} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  label="To"
                  name="to_month"
                  value={data["to_month"]}
                  minDate={data["from_month"]}
                  onChange={handleDateChange}
                />
              </Grid>
              <Grid item xs={12}>
                <MultiSelect
                  label="Employee(s)"
                  name="employee"
                  value={data["employee"]}
                  options={selectOptions["employees"]}
                  onChange={handleSelectChange}
                  onDeleteChip={handleDeleteChip}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </TabPanelsWrapper>
      </DialogContent>
      <DialogActions>
        <OutlinedButton label="Close" onClick={onClose} />
        <Button label="Export" onClick={handleSubmit} validate={validate()} isLoading={isLoading} />
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
