import jwtDecode from "jwt-decode";
import http from "./httpService";
import { apiEndpoint } from "./config.json";

http.setToken(getToken());

export function getToken() {
  return localStorage.getItem("token");
}

export const checkTokenValid = () => {
  const token = getToken();
  if (!token) return;
  const { exp } = jwtDecode(token);
  return Date.now() >= exp * 1000 ? false : true;
};

async function login(data) {
  const { data: token } = await http.post(`${apiEndpoint}/token/`, JSON.stringify(data));
  localStorage.setItem("token", token.access);
  localStorage.setItem("email", data.email);
  const user = await getCurrentUser();
  http.setToken(token.access);
  return { isAdmin: token.is_admin, user };
}

function logout() {
  localStorage.clear();
}

async function getCurrentUser() {
  const token = getToken();
  const email = localStorage.getItem("email");
  const { data: employees } = await http.get(`${apiEndpoint}/v1/staff/`, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return employees.filter(employee => employee.email === email)[0];
}

async function checkIsAdmin() {
  const token = getToken();
  const { data: isAdmin } = await http.get(`${apiEndpoint}/v1/staff/roles_check/`, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return isAdmin.is_admin;
}

export default { login, logout, checkTokenValid, checkIsAdmin, getCurrentUser };
