import { useEffect, useState } from "react";
import http from "../services/httpService";
import { apiEndpoint } from "../services/config";

const useClock = () => {
  const [clockStatus, setClockStatus] = useState({ isClockedIn: false, clockedInSiteService: "" });

  useEffect(() => {
    let isCancelled = false;
    http.get(`${apiEndpoint}/v1/attendance/status/`).then(({ data }) => {
      if (!isCancelled)
        setClockStatus({
          isClockedIn: data.current_clock_status === 0 ? true : false,
          clockedInSiteService: data.current_outlet_service
        });
    });
    return () => (isCancelled = true);
  }, []);

  return { clockStatus, setClockStatus };
};

export default useClock;
