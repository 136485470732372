import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../../context/auth-context";
import http from "../../../../services/httpService";
import { apiEndpoint } from "../../../../services/config";
import useClock from "../../../../hooks/clock";
import moment from "moment";
import _ from "lodash";

import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import SiteServiceDialog from "../../SiteServiceDialog/SiteServiceDialog";
import ClockingDialog from "../../ClockDialog";
import SiteServiceCard from "./SiteServiceCard";
import NoSiteService from "../../../commons/NoSiteService";

const initialClockingDialog = { isOpen: false, siteService: {}, action: "" };

const SiteServices = ({ siteServiceDialog, onToggleSiteServiceDialog }) => {
  const { user } = useContext(AuthContext);
  const [siteServices, setSiteServices] = useState([]);
  const { clockStatus, setClockStatus } = useClock();
  const [clockingDialog, setClockingDialog] = useState(initialClockingDialog);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;
    http.get(`${apiEndpoint}/v1/outlet_service/?service_date=${moment().format("DD/MM/YYYY")}`).then(({ data }) => {
      if (!isCancelled) {
        setSiteServices(data);
        setIsLoading(false);
      }
    });
    return () => (isCancelled = true);
  }, []);

  const toggleClockDialog = (siteService, action) => {
    if (action === "") setClockingDialog(initialClockingDialog);
    else setClockingDialog({ isOpen: !clockingDialog.isOpen, siteService, action });
  };

  const getPagedData = () => siteServices.filter(siteService => getIncompleteSiteServices(siteService));

  const getIncompleteSiteServices = siteService => {
    if (_.size(siteService.attendance) === 0) {
      return true;
    } else {
      let attendance = siteService.attendance.find(att => att.employee === user.id);
      if (attendance === undefined) return true;
      if (attendance.status === "DRAFT") return true;
    }
  };

  const updateSiteService = ({ id, outlet, attendance }, action) => {
    let newAttendance = { employee: user.id, outlet: outlet.id };
    if (action === 0) {
      //clock in
      newAttendance["status"] = "DRAFT";
      newAttendance["clock_in"] = moment.utc().format();
      newAttendance["clock_out"] = null;
      siteServices.forEach((siteService, index) => {
        if (siteService.id === id) siteServices[index].attendance.push(newAttendance);
      });
    } else if (action === 1) {
      //clock out
      newAttendance["status"] = "COMPLETED";
      newAttendance["clock_in"] = attendance.find(att => att.employee === user.id).clock_in;
      newAttendance["clock_out"] = moment.utc().format();
      siteServices.forEach((siteService, index) => {
        if (siteService.id === id) {
          siteServices[index].attendance.forEach((att, attIndex) => {
            if (att.employee === user.id) attendance[attIndex] = newAttendance;
          });
        }
      });
    }
    setClockStatus({
      isClockedIn: action === 0 ? true : false,
      clockedInSiteService: action === 0 ? id : ""
    });
    setSiteServices(siteServices);
  };

  const handleAddSiteService = siteService => setSiteServices(siteServices => [...siteServices, siteService]);

  return (
    <div className="section">
      {isLoading ? (
        <Skeleton variant="rect" height={320} />
      ) : _.size(getPagedData()) > 0 ? (
        <React.Fragment>
          <div className="section__title">Today's Site Service</div>
          <Grid container spacing={3}>
            {getPagedData().map(siteService => (
              <Grid key={siteService.uuid} item lg={3} md={6} xs={12}>
                <SiteServiceCard
                  siteService={siteService}
                  clockStatus={clockStatus}
                  onToggleClockDialog={toggleClockDialog}
                />
              </Grid>
            ))}
          </Grid>
        </React.Fragment>
      ) : (
        <NoSiteService content="There are no available site service." />
      )}
      <SiteServiceDialog
        isOpen={siteServiceDialog.isOpen}
        onClose={onToggleSiteServiceDialog}
        mode={siteServiceDialog.mode}
        onAddSiteService={handleAddSiteService}
      />
      <ClockingDialog
        isOpen={clockingDialog.isOpen}
        onClose={() => toggleClockDialog(initialClockingDialog, "")}
        action={clockingDialog.action}
        siteService={clockingDialog.siteService}
        onUpdateSiteService={updateSiteService}
      />
    </div>
  );
};

export default SiteServices;
