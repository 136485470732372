import { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";

const useFilter = (filterData, data) => {
  const [filter, setFilter] = useState(filterData);
  const [filtered, setFiltered] = useState(data);

  useEffect(() => {
    let newFilter = {};
    let newFiltered = data;

    for (const key in filter) {
      if (isNotObject(filter[key]) && filter[key]) newFilter[key] = filter[key];
      if (filter[key].value) {
        if (!Array.isArray(filter[key].value)) newFilter[key] = filter[key].value;
        else if (Array.isArray(filter[key].value) && _.size(filter[key].value) > 0) newFilter[key] = filter[key].value;
      }
    }

    if (!_.isEmpty(newFilter)) {
      for (const key in newFilter) {
        if (Array.isArray(newFilter[key]))
          //siteservice => siteservice[employees].some(employee => filter.employees.value.includes(employee.id))
          newFiltered = newFiltered.filter(d => d[key].some(x => filter[key].value.includes(x[filter[key].indicator])));
        else if (key === "service_date") newFiltered = newFiltered.filter(d => d[key] === newFilter[key]);
        else if (typeof newFilter[key] === "string" && key !== "service_date") {
          if (isNotObject(filter[key])) {
            newFiltered = newFiltered.filter(d => d[key].toLowerCase().indexOf(newFilter[key].toLowerCase()) !== -1);
          } else {
            newFiltered = newFiltered.filter(
              d => d[key][filter[key].indicator].toLowerCase().indexOf(newFilter[key].toLowerCase()) !== -1
            );
          }
        } else if (key=== "month" || "year") {
          continue;
        } else {
          if (isNotObject(filter[key])) {
            newFiltered = newFiltered.filter(d => d[key] === newFilter[key]);
          } else newFiltered = newFiltered.filter(d => d[key][filter[key].indicator] === newFilter[key]);
        }
      }
      setFiltered(newFiltered);
    } else setFiltered(data);
  }, [data, filter]);

  const handleTextChange = ({ target: input }) => {
    if (isNotObject(filter[input.name])) {
      setFilter(filter => ({ ...filter, [input.name]: input.value }));
    } else
      setFilter(filter => ({
        ...filter,
        [input.name]: { value: input.value, indicator: filter[input.name].indicator }
      }));
  };

  const handleSelectChange = ({ target: input }) => {
    if (isNotObject(filter[input.name])) {
      setFilter(filter => ({ ...filter, [input.name]: input.value }));
    } else
      setFilter(filter => ({
        ...filter,
        [input.name]: { value: input.value, indicator: filter[input.name].indicator }
      }));
  };

  const handleDeleteChip = (name, value) =>
    setFilter(filter => ({
      ...filter,
      [name]: { ...filter[name], value: filter[name].value.filter(item => item !== value) }
    }));

  const handleDateChange = name => date =>
    setFilter(filter => ({
      ...filter,
      [name]: { ...filter[name], value: moment(date).format("DD/MM/YYYY") }
    }));

  const handleCalendarChange = (date) => {
    let newFilter = {...filter};
    newFilter["month"] = {value: moment(date).month() + 1, indicator: "month"};
    newFilter["year"] = {value: moment(date).year(), indicator: "year"};
    setFilter(newFilter);
  }

  const handleClearDate = name => setFilter(filter => ({ ...filter, [name]: { value: null } }));

  const isNotObject = value => typeof value !== "object";

  return {
    filter,
    filtered,
    handleTextChange,
    handleSelectChange,
    handleDeleteChip,
    handleDateChange,
    handleCalendarChange,
    handleClearDate
  };
};

export default useFilter;
