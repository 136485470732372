import { useState } from "react";
import http from "../services/httpService";
import { apiEndpoint } from "../services/config";

const useSelectOptions = () => {
  const [selectOptions, setSelectOptions] = useState({});

  const getOptionsBy = async entity => {
    const entities = [];
    entity.forEach(ent => {
      switch (ent) {
        case "COMPANY":
          return entities.push({ entity: "companies", req: getCompanyOptions() });
        case "SITE":
          return entities.push({ entity: "sites", req: getSiteOptions() });
        case "EMPLOYEE":
          return entities.push({ entity: "employees", req: getEmployeeOptions() });
        case "SERVICE_TYPE":
          return entities.push({ entity: "serviceTypes", req: getServiceTypeOptions() });
        case "LOCATION":
          return entities.push({ entity: "locations", req: getLocationOptions() });
        default:
          throw new Error("Missing entity.");
      }
    });
    const selectOptions = await http.all(entities.map(entity => entity.req)).then(
      http.spread((...res) => {
        let result = {};
        entities.forEach((entity, index) => (result[entity.entity] = res[index].data));
        return result;
      })
    );
    return selectOptions;
  };

  const filterSitesByCompany = (sites, companyId) => sites.filter(site => site.company === companyId);

  const getCompanyOptions = () => http.get(`${apiEndpoint}/v1/outlet/company/`);

  const getSiteOptions = () => http.get(`${apiEndpoint}/v1/outlet_service/outlets/`);

  const getEmployeeOptions = () => http.get(`${apiEndpoint}/v1/outlet_service/employees/`);

  const getServiceTypeOptions = () => http.get(`${apiEndpoint}/v1/outlet_service/service_type/`);

  const getLocationOptions = () =>
    new Promise(resolve => resolve({ data: ["Central", "East", "North", "North East", "South", "West"] }));

  return { selectOptions, setSelectOptions, getOptionsBy, filterSitesByCompany };
};

export default useSelectOptions;
