import React from "react";

import { default as MuiSelect } from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";

const MultiSelect = ({
  label,
  name,
  value,
  options,
  onChange,
  onDeleteChip,
  fixedOptions = [],
  optionLabel = "name",
  indicator = "id"
}) => {
  const isObjectValue = options && options.every(option => typeof option === "object");

  const renderChips = selectedOptions =>
    selectedOptions.length > 0 ? (
      <Grid container spacing={1}>
        {selectedOptions.map(selected => {
          const item = options.find(option => option[indicator] === selected);
          const isFixed = fixedOptions.includes(selected) ? true : false;
          //Cancel render onDelete prop when option is fixed.
          const onDeleteProp = !isFixed && {
            onDelete: () => onDeleteChip(name, item[indicator])
          };
          return (
            <Grid key={item[indicator]} item>
              <Chip label={item[optionLabel]} {...onDeleteProp} />
            </Grid>
          );
        })}
      </Grid>
    ) : (
      <em>Select</em>
    );

  return (
    <div className="input">
      <div className="input__label">{label}</div>
      <div className="input__field">
        <MuiSelect
          variant="outlined"
          name={name}
          value={value}
          onChange={onChange}
          renderValue={selectedOptions => renderChips(selectedOptions)}
          multiple
          displayEmpty
          fullWidth
        >
          {options &&
            options.map(option => (
              <MenuItem
                key={isObjectValue ? option[indicator] : option}
                value={isObjectValue ? option[indicator] : option}
                disabled={fixedOptions.includes(isObjectValue ? option[indicator] : option) ? true : false}
              >
                {isObjectValue ? option[optionLabel] : option}
              </MenuItem>
            ))}
        </MuiSelect>
      </div>
    </div>
  );
};

export default MultiSelect;
