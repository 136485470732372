import React from "react";
import useFilter from "../../../hooks/filter";
import _ from "lodash";

import DataTable from "../../commons/table/DataTable/DataTable";
import Select from "../../commons/table/DataTable/FilterBarInputs/Select";
import Multiselect from "../../commons/table/DataTable/FilterBarInputs/Multiselect";
import DatePicker from "../../commons/table/DataTable/FilterBarInputs/DatePicker";
import Button from "../../commons/button/Button";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

const filterData = {
  company: { value: "", indicator: "id" },
  employees: { value: [], indicator: "id" },
  service_date: { value: null },
  service_type_details: { value: [], indicator: "id" }
};

const SiteServicesDataTable = ({ data, selectOptions, onEditSiteService, onDeleteSiteService }) => {
  const { filter, filtered, handleSelectChange, handleDeleteChip, handleDateChange, handleClearDate } = useFilter(
    filterData,
    data
  );

  const columns = [
    { path: "company.name", label: "Company" },
    { path: "outlet.name", label: "Site" },
    { path: "service_date", label: "Date" },
    {
      path: "employees",
      className: "employees-column",
      label: "Employee(s)",
      content: ({ employees }) => (
        <Grid container spacing={1}>
          {employees.map(employee => (
            <Grid key={employee.id} item>
              <Chip size="small" label={employee.name} />
            </Grid>
          ))}
        </Grid>
      )
    },
    {
      path: "service_type_details",
      className: "service_type-column",
      label: "Service Type",
      content: ({ service_type_details }) => (
        <Grid container spacing={1}>
          {service_type_details.map(service_type => (
            <Grid key={service_type.id} item>
              <Chip size="small" label={service_type.name} />
            </Grid>
          ))}
        </Grid>
      )
    },
    { path: "created_by.employee.name", label: "Created By" },
    {
      key: "actions",
      className: "actions-column",
      content: siteService => (
        <React.Fragment>
          <Button label="Edit" size="small" color="secondary" onClick={() => onEditSiteService(siteService)} />
          <Button label="Delete" size="small" danger onClick={() => onDeleteSiteService(siteService.uuid)} />
        </React.Fragment>
      )
    }
  ];

  const filterBarInputs = [
    <Select
      label="Company"
      name="company"
      value={filter.company.value}
      options={selectOptions["companies"]}
      onChange={handleSelectChange}
    />,
    <Multiselect
      label="Employee(s)"
      name="employees"
      value={filter.employees.value}
      options={selectOptions["employees"]}
      onChange={handleSelectChange}
      onDeleteChip={handleDeleteChip}
    />,
    <DatePicker
      label="Date"
      name="service_date"
      value={filter.service_date.value}
      onChange={handleDateChange}
      onClear={handleClearDate}
    />,
    <Multiselect
      label="Service Type(s)"
      name="service_type_details"
      value={filter.service_type_details.value}
      options={selectOptions["serviceTypes"]}
      onChange={handleSelectChange}
      onDeleteChip={handleDeleteChip}
    />
  ];

  return (
    <DataTable
      title="Site Services"
      columns={columns}
      data={filtered}
      filter={filter}
      filterBarInputs={filterBarInputs}
    />
  );
};

export default SiteServicesDataTable;
