import React from "react";

const FilterBarInput = ({ label, children }) => (
  <div className="filter">
    <div className="filter__label">{label}</div>
    <div className="filter__field">{children}</div>
  </div>
);

export default FilterBarInput;
