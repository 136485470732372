import React from "react";

import Dialog from "../../../commons/dialog/Dialog";
import Create from "./Create";
import Edit from "./Edit";

const ServiceTypeDialog = ({ isOpen, onClose, mode, serviceType, onAddServiceType, onEditServiceType }) => {
  const renderContent = mode => {
    switch (mode) {
      case "CREATE":
        return <Create onClose={onClose} onAddServiceType={onAddServiceType} />;
      case "EDIT":
        return <Edit onClose={onClose} serviceType={serviceType} onEditServiceType={onEditServiceType} />;
      default:
        return <div></div>;
    }
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} size="xs">
      {renderContent(mode)}
    </Dialog>
  );
};

export default ServiceTypeDialog;
