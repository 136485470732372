import React from "react";

import { CirclePicker } from "react-color";

const ColorPicker = ({ label, value, onChange }) => {
  const colors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#556cdc",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#607d8b",
    "#e0e0e0"
  ];
  return (
    <div className="input">
      <div className="input__label">{label}</div>
      <div className="input__field">
        <CirclePicker color={value} onChangeComplete={onChange} colors={colors} width="100%" />
      </div>
    </div>
  );
};

export default ColorPicker;
