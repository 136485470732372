import React from "react";

import Grid from "@material-ui/core/Grid";

const NoSiteService = ({ content }) => (
  <Grid container>
    <Grid item xs={12}>
      <div className="no-site-service-wrapper">{content}</div>
    </Grid>
  </Grid>
);

export default NoSiteService;
