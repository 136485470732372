import React from "react";

import { default as MuiDialog } from "@material-ui/core/Dialog";

const Dialog = ({ isOpen, onClose, onEnter, onExited, size, children }) => (
  <MuiDialog
    open={isOpen}
    onClose={onClose}
    onEnter={onEnter}
    onExited={onExited}
    maxWidth={size}
    fullWidth
    classes={{ paper: "dialog" }}
  >
    {children}
  </MuiDialog>
);

export default Dialog;
