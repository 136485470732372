import React, { useEffect, useState } from "react";
import http from "../../../../services/httpService";
import { apiEndpoint } from "../../../../services/config";
import useForm from "../../../../hooks/form";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import _ from "lodash";

import DialogHeader from "../../../commons/dialog/DialogHeader";
import DialogContent from "../../../commons/dialog/DialogContent";
import DialogActions from "../../../commons/dialog/DialogActions";
import TextField from "../../../commons/input/TextField";
import ColorPicker from "../../../commons/input/ColorPicker";
import Button from "../../../commons/button/Button";
import OutlinedButton from "../../../commons/button/OutlinedButton";

const initialData = { name: "", color: "#e0e0e0" };

const schema = {
  name: Joi.string()
    .required()
    .trim()
    .error(() => {
      return { message: "Name is required." };
    }),
  color: Joi.string().optional()
};

const Edit = ({ onClose, company, onEditCompany }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    data,
    errors,
    originalData,
    handleTextFieldChange,
    handleColorChange,
    validate,
    setCurrentOriginalData,
    compareData
  } = useForm(initialData, schema);

  useEffect(() => {
    let data = { name: company.name, color: company.color };
    setCurrentOriginalData(data);
  }, []);

  const handleSubmit = () => {
    if (!_.isEqual(data, originalData)) {
      setIsLoading(true);
      let newData = compareData(data, originalData);
      http.patch(`${apiEndpoint}/v1/outlet/company/${company.id}/`, JSON.stringify(newData)).then(({ data }) => {
        toast(`${data.name} has been edited.`, { type: "success" });
        onClose();
        onEditCompany(data);
      });
    } else {
      toast("No changes have been made.", { type: "info" });
    }
  };

  return (
    <React.Fragment>
      <DialogHeader>Edit {company.name}</DialogHeader>
      <DialogContent>
        <TextField
          label="Name"
          name="name"
          value={data["name"]}
          errors={errors["name"]}
          onChange={handleTextFieldChange}
        />
        <ColorPicker label="Color" value={data["color"]} onChange={handleColorChange} />
      </DialogContent>
      <DialogActions>
        <OutlinedButton label="Cancel" onClick={onClose} />
        <Button label="Save Changes" validate={validate()} isLoading={isLoading} onClick={handleSubmit} />
      </DialogActions>
    </React.Fragment>
  );
};

export default Edit;
