import React, { useState } from "react";

import Header from "../../commons/Header";
import PageContent from "../../commons/PageContent";
import ActionBar from "../../commons/ActionBar";
import Button from "../../commons/button/Button";
import TodaysSiteServices from "./SiteServices/TodaysSiteServices";
import UpcomingSiteServices from "./SiteServices/UpcomingSiteServices";

const initialSiteServiceDialog = { isOpen: false, mode: "CREATE", siteService: {} };

const Dashboard = () => {
  const [siteServiceDialog, setSiteServiceDialog] = useState(initialSiteServiceDialog);

  const toggleSiteServiceDialog = () =>
    setSiteServiceDialog(siteServiceDialog => ({
      ...siteServiceDialog,
      isOpen: !siteServiceDialog.isOpen
    }));

  return (
    <React.Fragment>
      <Header title="Dashboard" />
      <PageContent>
        <ActionBar>
          <Button label="Create Site Service" onClick={toggleSiteServiceDialog} />
        </ActionBar>
        <TodaysSiteServices siteServiceDialog={siteServiceDialog} onToggleSiteServiceDialog={toggleSiteServiceDialog} />
        <UpcomingSiteServices />
      </PageContent>
    </React.Fragment>
  );
};

export default Dashboard;
