import React, { useState, useEffect } from "react";
import http from "../../../../services/httpService";
import { apiEndpoint } from "../../../../services/config";
import _ from "lodash";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Card from "../../../commons/card/Card";
import CardTitle from "../../../commons/card/CardTitle";
import CardContent from "../../../commons/card/CardContent";
import Detail from "../../../commons/Detail";

const UpcomingSiteServices = () => {
  const [siteServices, setSiteServices] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    http
      .get(
        `${apiEndpoint}/v1/outlet_service/?service_date=${moment()
          .add(1, "days")
          .format("DD/MM/YYYY")}`
      )
      .then(({ data }) => !isCancelled && setSiteServices(data));
    return () => (isCancelled = true);
  }, []);

  return _.size(siteServices) > 0 ? (
    <div className="section">
      <div className="section__title">Next day's Site Service</div>
      <Grid container spacing={3}>
        {siteServices.map(siteService => (
          <Grid key={siteService.uuid} item lg={3} md={6} xs={12}>
            <Card>
              <CardTitle>{siteService.outlet.name}</CardTitle>
              <CardContent>
                <Detail label="Company">{siteService.company.name}</Detail>
                <Detail label="Service Type(s)">
                  <Grid container spacing={1}>
                    {siteService.service_type_details.map(serviceType => (
                      <Grid key={serviceType.id} item>
                        <Chip label={serviceType.name} />
                      </Grid>
                    ))}
                  </Grid>
                </Detail>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  ) : null;
};

export default UpcomingSiteServices;
