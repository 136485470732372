import React, { useEffect, useState, useReducer } from "react";
import { apiEndpoint } from "../../../services/config";
import http from "../../../services/httpService";
import useFilter from "../../../hooks/filter";
import useSelectOptions from "../../../hooks/selectOptions";
import useDelete from "../../../hooks/delete";

import Header from "../../commons/Header";
import PageContent from "../../commons/PageContent";
import ActionBar from "../../commons/ActionBar";
import FilterBar from "../../commons/FilterBar";
import Select from "../../commons/input/Select";
import TextField from "../../commons/input/TextField";
import Table from "../../commons/table/Table";
import Button from "../../commons/button/Button";
import SiteDialog from "./SiteDialog/SiteDialog";
import Grid from "@material-ui/core/Grid";

const filterData = { company: "", name: "" };
const initialSiteDialog = { isOpen: false, mode: "CREATE", site: {} };

const siteDialogReducer = (state, action) => {
  switch (action.type) {
    case "CREATE":
      return { ...state, isOpen: true };
    case "EDIT":
      return { isOpen: true, mode: "EDIT", site: action.site };
    case "CLOSE":
      return initialSiteDialog;
    default:
      throw new Error("Invalid action type.");
  }
};

const Sites = () => {
  const [sites, setSites] = useState([]);
  const [siteDialog, dispatch] = useReducer(siteDialogReducer, initialSiteDialog);
  const { filter, filtered, handleTextChange, handleSelectChange } = useFilter(filterData, sites);
  const { selectOptions, setSelectOptions, getOptionsBy } = useSelectOptions();
  const { handleDelete, renderDeleteDialog } = useDelete({
    api: `${apiEndpoint}/v1/outlet/`,
    callback: handleDeleteSite
  });

  const columns = [
    { path: "name", label: "Name" },
    {
      key: "actions",
      className: "actions-column",
      content: site => (
        <React.Fragment>
          <Button label="Edit" size="small" color="secondary" onClick={() => toggleDialog({ type: "EDIT", site })} />
          <Button label="Delete" size="small" onClick={() => handleDelete(site.uuid)} danger />
        </React.Fragment>
      )
    }
  ];

  useEffect(() => {
    let isCancelled = false;
    getOptionsBy(["COMPANY", "LOCATION"]).then(data => {
      if (!isCancelled) setSelectOptions(data);
    });
    http.get(`${apiEndpoint}/v1/outlet`).then(({ data }) => {
      if (!isCancelled) setSites(data);
    });
    return () => (isCancelled = true);
  }, []);

  const renderTables = () => {
    const locations = ["Central", "East", "North", "North East", "South", "West"];
    let result = {};
    locations.forEach(location => (result[location] = filtered.filter(site => site.location === location)));
    const tables = [];
    for (const location in result) {
      if (result[location].length > 0)
        tables.push(
          <Grid key={location} item xs={12} lg={6} xl={4}>
            <Table title={location} columns={columns} data={result[location]} />
          </Grid>
        );
    }
    return tables;
  };

  const toggleDialog = type => dispatch(type);

  function handleAddSite(site) {
    setSites(sites => [...sites, site]);
  }

  function handleEditSite(data) {
    let newSites = [...sites];
    sites.forEach((site, index) => {
      if (site.id === data.id) newSites[index] = data;
    });
    setSites(newSites);
  }

  function handleDeleteSite(uuid) {
    setSites(sites.filter(site => site.uuid !== uuid));
  }

  return (
    <React.Fragment>
      <Header title="Sites" />
      <PageContent>
        <ActionBar>
          <Button label="Add" onClick={() => toggleDialog({ type: "CREATE" })} size="large" />
        </ActionBar>
        <FilterBar>
          <Select
            label="Company"
            name="company"
            value={filter.company}
            options={selectOptions["companies"]}
            onChange={handleSelectChange}
          />
          <TextField label="Site" name="name" value={filter.name} onChange={handleTextChange} />
        </FilterBar>
        <Grid container spacing={3}>
          {renderTables()}
        </Grid>
      </PageContent>
      <SiteDialog
        isOpen={siteDialog.isOpen}
        onClose={() => toggleDialog({ type: "CLOSE" })}
        mode={siteDialog.mode}
        site={siteDialog.site}
        selectOptions={selectOptions}
        onAddSite={handleAddSite}
        onEditSite={handleEditSite}
      />
      {renderDeleteDialog()}
    </React.Fragment>
  );
};

export default Sites;
