import React, { useState } from "react";
import http from "../../../../services/httpService";
import { apiEndpoint } from "../../../../services/config";
import useForm from "../../../../hooks/form";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import DialogHeader from "../../../commons/dialog/DialogHeader";
import DialogContent from "../../../commons/dialog/DialogContent";
import DialogActions from "../../../commons/dialog/DialogActions";
import TextField from "../../../commons/input/TextField";
import MultilineTextField from "../../../commons/input/MultilineTextField";
import Select from "../../../commons/input/Select";
import Button from "../../../commons/button/Button";
import OutlinedButton from "../../../commons/button/OutlinedButton";

const initialData = { company: "", name: "", location: "", address: "" };

const schema = {
  company: Joi.number()
    .integer()
    .required()
    .label("Company"),
  name: Joi.string()
    .required()
    .trim()
    .label("Name")
    .error(() => ({ message: "Site name is required." })),
  location: Joi.string()
    .required()
    .label("Location"),
  address: Joi.string()
    .required()
    .label("Address")
    .error(() => ({ message: "Site address is required." }))
};

const Create = ({ onClose, selectOptions, onAddSite }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { data, errors, handleTextFieldChange, handleSelectChange, validate } = useForm(initialData, schema);

  const handleSubmit = () => {
    setIsLoading(true);
    http.post(`${apiEndpoint}/v1/outlet/`, JSON.stringify(data)).then(({ data }) => {
      onClose();
      toast(`${data.name} has been added.`, { type: "success" });
      onAddSite(data);
    });
  };

  return (
    <React.Fragment>
      <DialogHeader>Create Site</DialogHeader>
      <DialogContent>
        <Select
          label="Company"
          name="company"
          value={data["company"]}
          options={selectOptions["companies"]}
          onChange={handleSelectChange}
        />
        <TextField
          label="Name"
          name="name"
          value={data["name"]}
          errors={errors["name"]}
          onChange={handleTextFieldChange}
        />
        <Select
          label="Location"
          name="location"
          value={data["location"]}
          options={selectOptions["locations"]}
          onChange={handleSelectChange}
        />
        <MultilineTextField
          label="Address"
          name="address"
          value={data["address"]}
          errors={errors["address"]}
          onChange={handleTextFieldChange}
        />
      </DialogContent>
      <DialogActions>
        <OutlinedButton label="Cancel" onClick={onClose} />
        <Button label="Create" isLoading={isLoading} onClick={handleSubmit} validate={validate()} />
      </DialogActions>
    </React.Fragment>
  );
};

export default Create;
