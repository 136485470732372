import React, { useReducer, useEffect } from "react";
import http from "../../../services/httpService";
import { apiEndpoint } from "../../../services/config";
import { sortByDate } from "../../../services/siteServiceService";
import useSelectOptions from "../../../hooks/selectOptions";
import useDelete from "../../../hooks/delete";
import moment from "moment";

import Header from "../../commons/Header";
import PageContent from "../../commons/PageContent";
import ActionBar from "../../commons/ActionBar";
import Button from "../../commons/button/Button";
import SiteServicesDataTable from "./SiteServicesDataTable";
import SiteServiceDialog from "./SiteServiceDialog/SiteServiceDialog";

const initialSiteServiceDialog = { isOpen: false, mode: "CREATE", siteService: {} };

const siteServicesReducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.siteServices.sort(sortByDate);
    case "ADD":
      return [...state, action.siteService].sort(sortByDate);
    case "REMOVE":
      return state.filter(siteService => siteService.uuid !== action.uuid);
    default:
      throw new Error("Invalid action type.");
  }
};

const siteServiceDialogReducer = (state, { type, mode, date, siteService }) => {
  switch (type) {
    case "OPEN":
      return {
        isOpen: true,
        mode,
        date: date || "",
        siteService: siteService || {}
      };
    case "CLOSE":
      return { ...state, isOpen: false };
    case "RESET":
      return initialSiteServiceDialog;
    default:
      throw new Error("Invalid action type.");
  }
};

const SiteServices = () => {
  const [siteServices, dispatchSS] = useReducer(siteServicesReducer, []);
  const [siteServiceDialog, dispatchSSDialog] = useReducer(siteServiceDialogReducer, initialSiteServiceDialog);
  const { selectOptions, setSelectOptions, getOptionsBy } = useSelectOptions();
  const { handleDelete, renderDeleteDialog } = useDelete({
    api: `${apiEndpoint}/v1/outlet_service/`,
    callback: handleRemoveSiteService,
    customToast: { message: "Site service has been deleted.", type: "info" }
  });

  useEffect(() => {
    let isCancelled = false;
    getOptionsBy(["COMPANY", "SITE", "EMPLOYEE", "SERVICE_TYPE"]).then(
      options => !isCancelled && setSelectOptions(options)
    );
    http
      .get(`${apiEndpoint}/v1/outlet_service/`, { params: { month: moment().month() + 1, year: moment().year() }})
      .then(({ data: siteServices }) => !isCancelled && dispatchSS({ type: "SET", siteServices }));
    return () => (isCancelled = true);
  }, []);

  const handleEditSiteService = siteService => dispatchSSDialog({ type: "OPEN", mode: "EDIT", siteService });

  function handleAddSiteService(data) {
    data["start"] = data["end"] = moment(data.service_date, "DD/MM/YYYY");
    dispatchSS({ type: "ADD", siteService: data });
  }

  function handleChangeSiteService(data) {
    let newSiteServices = [...siteServices];
    newSiteServices.forEach((siteService, index) => {
      if (siteService.id === data.id) {
        data.start = data.end = moment(data.service_date, "DD/MM/YYYY");
        newSiteServices[index] = data;
      }
    });
    dispatchSS({ type: "SET", siteServices: newSiteServices });
  }

  function handleRemoveSiteService(uuid) {
    dispatchSS({ type: "REMOVE", uuid });
  }

  return (
    <React.Fragment>
      <Header title="Site Services" />
      <PageContent>
        <ActionBar>
          <Button label="Create" onClick={() => dispatchSSDialog({ type: "OPEN", mode: "CREATE" })} />
        </ActionBar>
        <SiteServicesDataTable
          data={siteServices}
          selectOptions={selectOptions}
          onEditSiteService={handleEditSiteService}
          onDeleteSiteService={handleDelete}
        />
        <SiteServiceDialog
          isOpen={siteServiceDialog.isOpen}
          onClose={() => dispatchSSDialog({ type: "CLOSE" })}
          onExited={() => dispatchSSDialog({ type: "RESET" })}
          mode={siteServiceDialog.mode}
          siteService={siteServiceDialog.siteService}
          selectOptions={selectOptions}
          onAddSiteService={handleAddSiteService}
          onChangeSiteService={handleChangeSiteService}
          onRemoveSiteService={handleRemoveSiteService}
        />
        {renderDeleteDialog()}
      </PageContent>
    </React.Fragment>
  );
};

export default SiteServices;
