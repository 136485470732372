import React, { useState } from "react";
import http from "../../../../services/httpService";
import { apiEndpoint } from "../../../../services/config";
import useForm from "../../../../hooks/form";
import useSelectOptions from "../../../../hooks/selectOptions";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import moment from "moment";

import DialogHeader from "../../../commons/dialog/DialogHeader";
import DialogContent from "../../../commons/dialog/DialogContent";
import DialogAction from "../../../commons/dialog/DialogActions";
import Select from "../../../commons/input/Select";
import MultiSelect from "../../../commons/input/MultiSelect";
import DatePicker from "../../../commons/input/DatePicker";
import Button from "../../../commons/button/Button";
import OutlinedButton from "../../../commons/button/OutlinedButton";

const initialData = { company: 1, site: "", employees: [], serviceTypes: [] };

const schema = {
  date: Joi.object().required(),
  company: Joi.number()
    .integer()
    .required(),
  site: Joi.number()
    .integer()
    .required(),
  employees: Joi.array()
    .min(1)
    .required(),
  serviceTypes: Joi.array()
    .min(1)
    .required()
};

const Create = ({ date, selectOptions, onClose, onAddSiteService }) => {
  const { filterSitesByCompany } = useSelectOptions();
  const [isLoading, setIsLoading] = useState(false);
  const { data, handleSelectChange, handleDateChange, handleDeleteChip, validate } = useForm(
    { ...initialData, date },
    schema
  );

  const handleSubmit = () => {
    setIsLoading(true);
    const formData = {
      service_date: moment(data.date).format("DD/MM/YYYY"),
      outlet_id: data.site,
      employee_ids: data.employees,
      service_type: data.serviceTypes
    };
    http.post(`${apiEndpoint}/v1/outlet_service/`, JSON.stringify(formData)).then(({ data }) => {
      onClose();
      toast("Site service created.", { type: "success" });
      onAddSiteService(data);
    });
  };

  return (
    <React.Fragment>
      <DialogHeader>Create Site Service</DialogHeader>
      <DialogContent>
        <DatePicker label="Date" name="date" value={data["date"]} onChange={handleDateChange} />
        <Select
          label="Company"
          name="company"
          value={data["company"]}
          options={selectOptions["companies"]}
          onChange={handleSelectChange}
        />
        <Select
          label="Site"
          name="site"
          value={data["site"]}
          options={filterSitesByCompany(selectOptions["sites"], data["company"])}
          onChange={handleSelectChange}
        />
        <MultiSelect
          label="Employee(s)"
          name="employees"
          value={data["employees"]}
          options={selectOptions["employees"]}
          onChange={handleSelectChange}
          onDeleteChip={handleDeleteChip}
        />
        <MultiSelect
          label="Service Type(s)"
          name="serviceTypes"
          value={data["serviceTypes"]}
          options={selectOptions["serviceTypes"]}
          onChange={handleSelectChange}
          onDeleteChip={handleDeleteChip}
        />
      </DialogContent>
      <DialogAction>
        <OutlinedButton label="Cancel" onClick={onClose} />
        <Button label="Create" onClick={handleSubmit} isLoading={isLoading} validate={validate()} />
      </DialogAction>
    </React.Fragment>
  );
};

export default Create;
