import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import auth from "../services/authService";
import { toast } from "react-toastify";

const initialState = {
  isAuth: false,
  isAdmin: false,
  isLoading: true,
  user: {}
};

export const AuthContext = React.createContext({
  ...initialState,
  login: () => {},
  logout: () => {}
});

const AuthContextProvider = props => {
  const [authentication, setAuthentication] = useState(initialState);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      const isTokenValid = auth.checkTokenValid();
      if (!token || !isTokenValid) props.history.push("/Login");
      else {
        const user = await auth.getCurrentUser();
        auth.checkIsAdmin().then(isAdmin => setAuthentication({ isAuth: true, isAdmin, isLoading: false, user }));
      }
    };
    fetchData();
  }, []);

  const handleLogin = data => {
    auth
      .login(data)
      .then(({ isAdmin, user }) => {
        setAuthentication({ isAuth: true, isAdmin, isLoading: false, user });
        props.history.push("/");
      })
      .catch(({ response }) => {
        if (response.status === 401) toast("Incorrect email or password.", { type: "error" });
      });
  };

  const handleLogout = () => {
    localStorage.clear();
    setAuthentication(initialState);
    props.history.push("/Login");
  };

  return (
    <AuthContext.Provider
      value={{
        user: authentication.user,
        login: handleLogin,
        logout: handleLogout,
        isAuth: authentication.isAuth,
        isAdmin: authentication.isAdmin,
        isLoading: authentication.isLoading
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default withRouter(AuthContextProvider);
