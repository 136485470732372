import React from "react";

import { default as MuiTextField } from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

const TextField = ({ label, name, value, errors, helperText, onChange, type = "text" }) => (
  <div className="input">
    <div className="input__label">{label}</div>
    <div className="input__field">
      <FormControl error={errors ? true : false} fullWidth>
        <MuiTextField
          variant="outlined"
          name={name}
          value={value}
          error={errors ? true : false}
          onChange={onChange}
          helperText={helperText}
          type={type}
        />
        {errors ? <FormHelperText>{errors}</FormHelperText> : null}
      </FormControl>
    </div>
  </div>
);

export default TextField;
