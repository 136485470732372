import React from "react";

import TableHeader from "../TableHeader";
import TableBody from "../TableBody";

const DataTable = ({ title, columns, data, filterBarInputs }) => (
  <div className="table">
    <div className="table__header">{title}</div>
    <div className="table__actions">
      <div className="filters">
        {filterBarInputs.map((filterBarInput, index) => (
          <React.Fragment key={index}>{filterBarInput}</React.Fragment>
        ))}
      </div>
      <div className="search"></div>
    </div>
    <div className="table__content">
      <table>
        <TableHeader columns={columns} />
        <TableBody columns={columns} data={data} />
      </table>
    </div>
    <div className="table__footer"></div>
  </div>
);

export default DataTable;
