import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { NavLinksContext } from "../context/navlinks-context";
import { AuthContext } from "../context/auth-context";
import clsx from "clsx";
import ReactAvatar from "react-avatar";

import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const Sidebar = () => {
  const { navLinks } = useContext(NavLinksContext);
  const { user, logout } = useContext(AuthContext);
  const [isSidebarExpanded, toggleSidebar] = useState(false);

  return (
    <Drawer
      variant="permanent"
      className={clsx("sidebar", isSidebarExpanded && "expanded")}
      classes={{
        paper: clsx("sidebar__content scrollbar", isSidebarExpanded && "expanded")
      }}
    >
      <div>
        <div className={clsx("expand-button", isSidebarExpanded && "expand-button-expanded")}>
          <IconButton onClick={() => toggleSidebar(!isSidebarExpanded)}>
            <MenuIcon style={{ fill: "white" }} />
          </IconButton>
        </div>
        <div className="profile">
          <ReactAvatar round src={user.image} name={user.name} size={isSidebarExpanded ? 60 : 32} className="avatar" />
        </div>
        <div className="navigation">
          <ul>
            {navLinks.map((link, index) => (
              <li key={index} className="link">
                <NavLink to={link.path}>
                  {link.icon}
                  {link.label}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="footer">
        <button onClick={logout}>
          <ExitToAppIcon />
          Logout
        </button>
      </div>
    </Drawer>
  );
};

export default Sidebar;
