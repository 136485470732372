import React from "react";

import Card from "../../../commons/card/Card";
import CardTitle from "../../../commons/card/CardTitle";
import CardContent from "../../../commons/card/CardContent";
import CardActions from "../../../commons/card/CardActions";
import Detail from "../../../commons/Detail";
import TextButton from "../../../commons/button/TextButton";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

const SiteServiceCard = ({ onToggleClockDialog, ...props }) => {
  const { id, outlet, company, service_type_details } = props.siteService;
  const { isClockedIn, clockedInSiteService } = props.clockStatus;

  return (
    <Card>
      <CardTitle>
        {outlet.name}
        {clockedInSiteService === id && <div className="servicing">Currently Servicing</div>}
      </CardTitle>
      <CardContent>
        <Detail label="Company">{company.name}</Detail>
        <Detail label="Service Type(s)">
          <Grid container spacing={1}>
            {service_type_details.map(serviceType => (
              <Grid key={serviceType.id} item>
                <Chip label={serviceType.name} />
              </Grid>
            ))}
          </Grid>
        </Detail>
      </CardContent>
      <CardActions>
        {clockedInSiteService === id ? (
          <TextButton label="Clock out" onClick={() => onToggleClockDialog(props.siteService, "OUT")} />
        ) : (
          <TextButton
            label="Clock in"
            onClick={() => onToggleClockDialog(props.siteService, "IN")}
            disabled={isClockedIn}
          />
        )}
      </CardActions>
    </Card>
  );
};

export default SiteServiceCard;
