import React, { useEffect, useState } from "react";
import http from "../../../../services/httpService";
import { apiEndpoint } from "../../../../services/config";
import useForm from "../../../../hooks/form";
import Joi from "joi-browser";
import _ from "lodash";
import { toast } from "react-toastify";

import DialogHeader from "../../../commons/dialog/DialogHeader";
import DialogContent from "../../../commons/dialog/DialogContent";
import DialogActions from "../../../commons/dialog/DialogActions";
import Select from "../../../commons/input/Select";
import TextField from "../../../commons/input/TextField";
import MultilineTextField from "../../../commons/input/MultilineTextField";
import Button from "../../../commons/button/Button";
import OutlinedButton from "../../../commons/button/OutlinedButton";

const initialData = { company: "", name: "", location: "", address: "" };

const schema = {
  company: Joi.number()
    .integer()
    .required()
    .label("Company"),
  name: Joi.string()
    .required()
    .trim()
    .label("Name")
    .error(() => ({ message: "Site name is required." })),
  location: Joi.string()
    .required()
    .label("Location"),
  address: Joi.string()
    .required()
    .label("Address")
    .error(() => ({ message: "Site address is required." }))
};

const Edit = ({ onClose, site, selectOptions, onEditSite }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    data,
    errors,
    originalData,
    handleTextFieldChange,
    handleSelectChange,
    validate,
    setCurrentOriginalData,
    compareData
  } = useForm(initialData, schema);

  useEffect(() => {
    let data = {
      company: site.company,
      name: site.name,
      location: site.location,
      address: site.address
    };
    setCurrentOriginalData(data);
  }, []);

  const handleSubmit = () => {
    if (!_.isEqual(data, originalData)) {
      setIsLoading(true);
      let newData = compareData(data, originalData);
      http.patch(`${apiEndpoint}/v1/outlet/${site.uuid}/`, JSON.stringify(newData)).then(({ data }) => {
        toast(`${site.name} has been edited.`, { type: "success" });
        onClose();
        onEditSite(data);
      });
    } else {
      toast("No changes have been made.", { type: "info" });
    }
  };

  return (
    <React.Fragment>
      <DialogHeader>Edit Site</DialogHeader>
      <DialogContent>
        <Select
          label="Company"
          name="company"
          value={data["company"]}
          options={selectOptions["companies"]}
          onChange={handleSelectChange}
        />
        <TextField
          label="Name"
          name="name"
          value={data["name"]}
          errors={errors["name"]}
          onChange={handleTextFieldChange}
        />
        <Select
          label="Location"
          name="location"
          value={data["location"]}
          options={selectOptions["locations"]}
          onChange={handleSelectChange}
        />
        <MultilineTextField
          label="Address"
          name="address"
          value={data["address"]}
          errors={errors["address"]}
          onChange={handleTextFieldChange}
        />
      </DialogContent>
      <DialogActions>
        <OutlinedButton label="Cancel" onClick={onClose} />
        <Button label="Save Changes" isLoading={isLoading} validate={validate()} onClick={handleSubmit} />
      </DialogActions>
    </React.Fragment>
  );
};

export default Edit;
