import React from "react";

import Dialog from "../../../commons/dialog/Dialog";
import Create from "./Create";
import Edit from "./Edit";

const SiteDialog = ({ isOpen, onClose, mode, site, selectOptions, onAddSite, onEditSite }) => {
  const renderContent = mode => {
    switch (mode) {
      case "CREATE":
        return <Create onClose={onClose} selectOptions={selectOptions} onAddSite={onAddSite} />;
      case "EDIT":
        return <Edit onClose={onClose} selectOptions={selectOptions} site={site} onEditSite={onEditSite} />;
      default:
        return <div></div>;
    }
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} size="xs">
      {renderContent(mode)}
    </Dialog>
  );
};

export default SiteDialog;
