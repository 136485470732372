import React from "react";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";

const MultilineTextField = ({ label, name, value, errors, onChange, helperText, rows = 3 }) => (
  <div className="input">
    <div className="input__label">{label}</div>
    <div className="input__field">
      <FormControl error={errors ? true : false} fullWidth>
        <TextField
          variant="outlined"
          name={name}
          value={value}
          error={errors ? true : false}
          helperText={helperText}
          rows={rows}
          onChange={onChange}
          multiline
        />
        {errors ? <FormHelperText>{errors}</FormHelperText> : null}
      </FormControl>
    </div>
  </div>
);

export default MultilineTextField;
