import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "./auth-context";

import DashboardIcon from "@material-ui/icons/DashboardTwoTone";
import PeopleIcon from "@material-ui/icons/PeopleAltTwoTone";
import HomeWorkIcon from "@material-ui/icons/HomeWorkTwoTone";
import StoreIcon from "@material-ui/icons/StoreTwoTone";
import LocalOfferIcon from "@material-ui/icons/LocalOfferTwoTone";
import ListAltIcon from "@material-ui/icons/ListAltTwoTone";

export const NavLinksContext = React.createContext({
  navLinks: []
});

const NavLinksContextProvider = props => {
  const { isAdmin } = useContext(AuthContext);
  const [navLinks, setNavLinks] = useState([]);

  useEffect(() => setupNavLinks(), []);

  const setupNavLinks = () => {
    let links;
    if (isAdmin)
      links = [
        { label: "Dashboard", path: "/Dashboard", icon: <DashboardIcon /> },
        { label: "Site Services", path: "/SiteServices", icon: <ListAltIcon /> },
        { label: "Employees", path: "/Employees", icon: <PeopleIcon /> },
        { label: "Company", path: "/Company", icon: <HomeWorkIcon /> },
        { label: "Sites", path: "/Sites", icon: <StoreIcon /> },
        { label: "Service Types", path: "/ServiceTypes", icon: <LocalOfferIcon /> }
      ];
    else
      links = [
        { label: "Dashboard", path: "/Dashboard", icon: <DashboardIcon /> },
        { label: "Site Services", path: "/SiteServices", icon: <ListAltIcon /> }
      ];
    setNavLinks(links);
  };

  return <NavLinksContext.Provider value={{ navLinks }}>{props.children}</NavLinksContext.Provider>;
};

export default NavLinksContextProvider;
