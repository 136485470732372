import React from "react";

const TableHeader = ({ columns }) => (
  <thead>
    <tr>
      {columns.map(column =>
        column.key ? (
          <th key={column.key} className={column.className}>
            {column.label && column.label}
          </th>
        ) : (
          <th key={column.path} className={column.className}>
            {column.label}
          </th>
        )
      )}
    </tr>
  </thead>
);

export default TableHeader;
