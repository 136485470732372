import React from "react";
import FilterBarInput from "./FilterBarInput";

import { default as MuiSelect } from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";

const Select = ({ label, name, value, options, onChange, indicator = "id", optionLabel = "name" }) => (
  <FilterBarInput label={label}>
    <MuiSelect
      name={name}
      value={value}
      onChange={onChange}
      displayEmpty
      input={<InputBase />}
      classes={{ select: "select-control" }}
    >
      <MenuItem value="">
        <em>Show All</em>
      </MenuItem>
      {options &&
        options.map(option => (
          <MenuItem key={option[indicator]} value={option[indicator]}>
            {option[optionLabel]}
          </MenuItem>
        ))}
    </MuiSelect>
  </FilterBarInput>
);

export default Select;
