import React, { useEffect, useState, useContext } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { AuthContext } from "../context/auth-context";
import { NavLinksContext } from "../context/navlinks-context";
import ReactAvatar from "react-avatar";
import { ReactSVG } from "react-svg";
import Logo from "../assets/images/arrival-logo.svg";

import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const Topbar = props => {
  const { navLinks } = useContext(NavLinksContext);
  const { user, logout } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => setIsOpen(false), [props.location]);

  const toggleDrawer = () => setIsOpen(!isOpen);

  return (
    <React.Fragment>
      <AppBar position="fixed" color="primary" onClick={toggleDrawer} className="topbar">
        <div className="topbar__content">
          <IconButton className="expand-button">
            <MenuIcon />
          </IconButton>
          <ReactSVG className="topbar-logo" src={Logo} />
        </div>
      </AppBar>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        className="mobile-sidebar"
        classes={{ paper: "mobile-sidebar__content" }}
      >
        <div>
          <div className="profile">
            <ReactAvatar round src={user.image} name={user.name} size={75} className="avatar" />
          </div>
          <div className="navigation">
            <ul>
              {navLinks.map((link, index) => (
                <li key={index} className="link">
                  <NavLink to={link.path}>
                    {link.icon}
                    {link.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="footer">
          <button onClick={logout}>
            <ExitToAppIcon />
            Logout
          </button>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default withRouter(Topbar);
