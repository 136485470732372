import React, { useContext } from "react";
import { AuthContext } from "../context/auth-context";
import NavLinksContextProvider from "../context/navlinks-context";

import "../styles/main.scss";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AdminMain from "../components/Admin/Main";
import UserMain from "../components/User/Main";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#8ed200", contrastText: "#fff" },
    secondary: { main: "#505050", contrastText: "#fff" }
  },
  typography: { fontFamily: '"Open Sans", sans-serif' },
  overrides: {
    MuiPaper: { root: { color: "#505050" } },
    MuiButton: { root: { fontWeight: 700 }, label: { minHeight: 24 } },
    MuiChip: { label: { fontWeight: 700 }, labelSmall: { fontSize: "0.7rem" } },
    MuiTab: { root: { fontWeight: 700 } }
  }
});

toast.configure({ className: "toast", toastClassName: "toast__body" });

const App = () => {
  const authContext = useContext(AuthContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return authContext.isLoading ? null : (
    <ThemeProvider theme={theme}>
      <main>
        <NavLinksContextProvider>{isMobile ? <Topbar /> : <Sidebar />}</NavLinksContextProvider>
        {authContext.isAdmin ? <AdminMain /> : <UserMain />}
      </main>
    </ThemeProvider>
  );
};

export default App;
