import React, { useEffect } from "react";
import useSelectOptions from "../../../hooks/selectOptions";

import Dialog from "../../commons/dialog/Dialog";
import Create from "./Create";

const SiteServiceDialog = ({ isOpen, onClose, mode, onAddSiteService }) => {
  const { selectOptions, setSelectOptions, getOptionsBy } = useSelectOptions();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) getOptionsBy(["COMPANY", "SITE", "SERVICE_TYPE"]).then(options => setSelectOptions(options));
    return () => (isCancelled = true);
  }, []);

  const renderContent = mode => {
    switch (mode) {
      case "CREATE":
        return <Create onClose={onClose} selectOptions={selectOptions} onAddSiteService={onAddSiteService} />;
      default:
        return <div></div>;
    }
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} size="xs">
      {renderContent(mode)}
    </Dialog>
  );
};

export default SiteServiceDialog;
