import React from "react";

import ReportIcon from "@material-ui/icons/ReportTwoTone";
import Tooltip from "@material-ui/core/Tooltip";

const NoSiteWarning = () => (
  <Tooltip title="Please add site to this company to get select options." arrow>
    <ReportIcon color="primary" />
  </Tooltip>
);

export default NoSiteWarning;
