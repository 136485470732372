import React from "react";
import { default as MuiButton } from "@material-ui/core/Button";

const OutlinedButton = ({ label, onClick }) => (
  <MuiButton variant="outlined" onClick={onClick}>
    {label}
  </MuiButton>
);

export default OutlinedButton;
