import { useState } from "react";

import Joi from "joi-browser";
import _ from "lodash";

const useForm = (initialData, schema = {}) => {
  const [data, setData] = useState(initialData);
  const [originalData, setOriginalData] = useState(initialData);
  const [errors, setErrors] = useState({});

  const setCurrentOriginalData = data => {
    setOriginalData(data);
    setData(data);
  };

  const changeData = data => setData(data);

  const compareData = (newData, originalData) => {
    let data = {};
    _.forIn(newData, (value, key) => {
      _.forIn(originalData, (oriValue, oriKey) => {
        if (key === oriKey && value !== oriValue) data[key] = newData[key];
      });
    });
    return data;
  };

  const resetData = () => {
    setData(initialData);
    setErrors({});
  };

  const validateInput = ({ name, value }) => {
    const input = { [name]: value };
    const inputSchema = { [name]: schema[name] };
    const { error } = Joi.validate(input, inputSchema);
    return error ? error.details[0].message : null;
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleTextFieldChange = ({ target: input }) => {
    const errorMessage = validateInput(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    setData(data => ({ ...data, [input.name]: input.value }));
  };

  const handleSelectChange = ({ target: input }) => setData(data => ({ ...data, [input.name]: input.value }));

  const handleDeleteChip = (name, value) =>
    setData(data => ({ ...data, [name]: data[name].filter(item => item !== value) }));

  const handleDateChange = key => date => setData({ ...data, [key]: date.toISOString() });

  const handleColorChange = color => setData(data => ({ ...data, color: color.hex }));

  return {
    data,
    originalData,
    errors,
    handleTextFieldChange,
    handleSelectChange,
    handleDeleteChip,
    handleDateChange,
    handleColorChange,
    validate,
    setCurrentOriginalData,
    changeData,
    compareData,
    resetData
  };
};

export default useForm;
