import React from "react";

import { default as MuiSelect } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const Select = ({ label, name, value, options, onChange, disabled, optionLabel = "name", indicator = "id" }) => {
  const isObjectValue = options && options.every(option => typeof option === "object");

  return (
    <div className="input">
      <div className="input__label">{label}</div>
      <div className="input__field">
        <MuiSelect
          variant="outlined"
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          displayEmpty
          fullWidth
        >
          <MenuItem value="">
            <em>Select</em>
          </MenuItem>
          {options &&
            options.map(option => (
              <MenuItem
                key={isObjectValue ? option[indicator] : option}
                value={isObjectValue ? option[indicator] : option}
              >
                {isObjectValue ? option[optionLabel] : option}
              </MenuItem>
            ))}
        </MuiSelect>
      </div>
    </div>
  );
};

export default Select;
