import React from "react";

import Dialog from "../../../commons/dialog/Dialog";
import Create from "./Create";
import Edit from "./Edit";

const CompanyDialog = ({ isOpen, onClose, mode, company, onAddCompany, onEditCompany }) => {
  const renderContent = mode => {
    switch (mode) {
      case "CREATE":
        return <Create onClose={onClose} onAddCompany={onAddCompany} />;
      case "EDIT":
        return <Edit onClose={onClose} company={company} onEditCompany={onEditCompany} />;
      default:
        throw new Error("Invalid mode");
    }
  };
  return (
    <Dialog isOpen={isOpen} onClose={onClose} size="xs">
      {renderContent(mode)}
    </Dialog>
  );
};

export default CompanyDialog;
