import React, { useReducer, useState } from "react";
import http from "../services/httpService";
import _ from "lodash";

import Dialog from "../components/commons/dialog/Dialog";
import DialogHeader from "../components/commons/dialog/DialogHeader";
import DialogContent from "../components/commons/dialog/DialogContent";
import DialogActions from "../components/commons/dialog/DialogActions";
import Button from "../components/commons/button/Button";
import OutlinedButton from "../components/commons/button/OutlinedButton";
import { toast } from "react-toastify";

const deleteReducer = (state, action) => {
  switch (action.type) {
    case "OPEN":
      return { isOpen: true, uuid: action.uuid };
    case "CLOSE":
      return { isOpen: false, uuid: "" };
    default:
      throw new Error("Invalid action type.");
  }
};

const useDelete = ({ api, callback, customToast = {} }) => {
  const [deleteDialog, dispatch] = useReducer(deleteReducer, { isOpen: false, uuid: "" });
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = uuid => dispatch({ type: "OPEN", uuid });

  const doDelete = uuid => {
    setIsLoading(true);
    http.delete(api + uuid + "/").then(() => {
      dispatch({ type: "CLOSE" });
      callback(uuid);
      if (_.isEmpty(customToast)) toast("Data deleted.", { type: "info" });
      else toast(customToast.message, { type: customToast.type });
      setIsLoading(false);
    });
  };

  const renderDeleteDialog = () => {
    return (
      <Dialog isOpen={deleteDialog.isOpen} onClose={() => dispatch({ type: "CLOSE" })} size="xs">
        <DialogHeader>Delete?</DialogHeader>
        <DialogContent>Do you really want to delete this?</DialogContent>
        <DialogActions>
          <OutlinedButton label="Cancel" onClick={() => dispatch({ type: "CLOSE" })} />
          <Button
            label="Delete"
            danger
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => doDelete(deleteDialog.uuid)}
          />
        </DialogActions>
      </Dialog>
    );
  };

  return { handleDelete, renderDeleteDialog };
};

export default useDelete;
