import React from "react";
import moment from "moment";

import Dialog from "../../../commons/dialog/Dialog";
import Create from "./Create";
import View from "./View";
import Edit from "./Edit";

const SiteServiceDialog = ({
  isOpen,
  onClose,
  onExited,
  date = moment(),
  mode,
  siteService,
  selectOptions,
  onToggleEditMode,
  onCancelEdit,
  onAddSiteService,
  onChangeSiteService,
  onRemoveSiteService
}) => {
  const renderContent = mode => {
    switch (mode) {
      case "CREATE":
        return (
          <Create date={date} selectOptions={selectOptions} onClose={onClose} onAddSiteService={onAddSiteService} />
        );
      case "VIEW":
        return (
          <View
            siteService={siteService}
            onClose={onClose}
            onToggleEditMode={onToggleEditMode}
            onRemoveSiteService={onRemoveSiteService}
          />
        );
      case "EDIT":
        return (
          <Edit
            selectOptions={selectOptions}
            siteService={siteService}
            onClose={onClose}
            onCancelEdit={onCancelEdit}
            onChangeSiteService={onChangeSiteService}
          />
        );
      default:
        return <div></div>;
    }
  };
  return (
    <Dialog isOpen={isOpen} onClose={onClose} onExited={onExited} size="xs">
      {renderContent(mode)}
    </Dialog>
  );
};

export default SiteServiceDialog;
