import React, { useContext, useState } from "react";
import { AuthContext } from "../context/auth-context";
import useForm from "../hooks/form";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Joi from "joi-browser";
import { ReactSVG } from "react-svg";

import Logo from "../assets/images/arrival-logo.svg";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "./commons/input/TextField";
import Button from "./commons/button/Button";

const theme = createMuiTheme({
  palette: { primary: { main: "#8ed200", contrastText: "#fff" } },
  typography: { fontFamily: '"Open Sans", sans-serif' },
  overrides: { MuiButton: { root: { fontWeight: 700 }, label: { minHeight: 24 } } }
});

const initialData = { email: "", password: "" };

const schema = {
  email: Joi.string()
    .email({ minDomainAtoms: 2, tldWhitelist: ["com"] })
    .required()
    .label("Email")
    .error(errors =>
      errors[0].context.value === "" ? { message: "Your email is required." } : { message: "Your email must be valid." }
    ),
  password: Joi.string()
    .required()
    .label("Password")
    .error(() => ({ message: "Password is required." }))
};

const Login = () => {
  const authContext = useContext(AuthContext);
  const { data, errors, handleTextFieldChange, validate } = useForm(initialData, schema);
  const [isLoading, setIsLoading] = useState(false);

  const doSubmit = e => {
    e.preventDefault();
    setIsLoading(true);
    authContext.login(data);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="login">
        <div className="login__content">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={6} className="login-form-wrapper">
                <form className="login-form" onSubmit={doSubmit}>
                  <div className="logo-wrapper">
                    <ReactSVG className="login-logo" src={Logo} />
                  </div>
                  <div className="input-wrapper">
                    <TextField
                      label="Email"
                      name="email"
                      value={data["email"]}
                      errors={errors["email"]}
                      onChange={handleTextFieldChange}
                    />
                    <TextField
                      label="Password"
                      name="password"
                      value={data["password"]}
                      errors={errors["password"]}
                      onChange={handleTextFieldChange}
                      type="password"
                    />
                    <Button label="Login" type="submit" validate={validate()} isLoading={isLoading} fullWidth />
                  </div>
                </form>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Login;
