import React, { useEffect } from "react";
import http from "../../../../services/httpService";
import { apiEndpoint } from "../../../../services/config";
import useForm from "../../../../hooks/form";
import useSelectOptions from "../../../../hooks/selectOptions";
import Joi from "joi-browser";
import _ from "lodash";
import { toast } from "react-toastify";

import DialogHeader from "../../../commons/dialog/DialogHeader";
import DialogContent from "../../../commons/dialog/DialogContent";
import DialogActions from "../../../commons/dialog/DialogActions";
import Select from "../../../commons/input/Select";
import MultiSelect from "../../../commons/input/MultiSelect";
import Button from "../../../commons/button/Button";
import OutlinedButton from "../../../commons/button/OutlinedButton";
import { useState } from "react";

const initialData = { company: "", outlet_id: "", employee_ids: [], service_type: [] };

const schema = {
  company: Joi.number()
    .integer()
    .required(),
  outlet_id: Joi.number()
    .integer()
    .required(),
  employee_ids: Joi.array()
    .min(1)
    .required(),
  service_type: Joi.array()
    .min(1)
    .required()
};

const Edit = ({ selectOptions, onCancelEdit, siteService, onClose, onChangeSiteService }) => {
  const { filterSitesByCompany } = useSelectOptions();
  const [isLoading, setIsLoading] = useState(false);
  const {
    data,
    originalData,
    handleSelectChange,
    handleDeleteChip,
    validate,
    setCurrentOriginalData,
    compareData
  } = useForm(initialData, schema);

  useEffect(() => {
    const data = {
      company: siteService.company.id,
      outlet_id: siteService.outlet.id,
      employee_ids: siteService.employees.map(employee => employee.id),
      service_type: siteService.service_type_details.map(serviceType => serviceType.id)
    };
    setCurrentOriginalData(data);
  }, []);

  const getFixedOptions = () => siteService.attendance.map(att => att.status === "COMPLETED" && att.employee);

  const submitChanges = () => {
    if (!_.isEqual(data, originalData)) {
      setIsLoading(true);
      let newData = compareData(data, originalData);
      http.patch(`${apiEndpoint}/v1/outlet_service/${siteService.uuid}/`, JSON.stringify(newData)).then(({ data }) => {
        onClose();
        toast("Site service edited.", { type: "success" });
        onChangeSiteService(data);
      });
    } else {
      toast("No changes have been made.", { type: "info" });
    }
  };

  return (
    <React.Fragment>
      <DialogHeader>{siteService.title}</DialogHeader>
      <DialogContent>
        <Select
          label="Company"
          name="company"
          value={data["company"]}
          options={selectOptions["companies"]}
          onChange={handleSelectChange}
        />
        <Select
          label="Site"
          name="outlet_id"
          value={data["outlet_id"]}
          options={filterSitesByCompany(selectOptions["sites"], data["company"])}
          onChange={handleSelectChange}
          disabled={data["company"] ? false : true}
        />
        <MultiSelect
          label="Employee(s)"
          name="employee_ids"
          value={data["employee_ids"]}
          options={selectOptions["employees"]}
          onChange={handleSelectChange}
          fixedOptions={getFixedOptions()}
          onDeleteChip={handleDeleteChip}
        />
        <MultiSelect
          label="Service Type(s)"
          name="service_type"
          value={data["service_type"]}
          options={selectOptions["serviceTypes"]}
          onChange={handleSelectChange}
          onDeleteChip={handleDeleteChip}
        />
      </DialogContent>
      <DialogActions>
        <OutlinedButton label="Cancel" onClick={onCancelEdit ? onCancelEdit : onClose} />
        <Button label="Save Changes" isLoading={isLoading} onClick={submitChanges} validate={validate(data, schema)} />
      </DialogActions>
    </React.Fragment>
  );
};

export default Edit;
