import React from "react";

const Detail = ({ label, children }) => (
  <div className="detail">
    <div className="detail__label">{label}</div>
    <div className="detail__content">{children}</div>
  </div>
);

export default Detail;
