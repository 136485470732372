import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ReactSVG } from "react-svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import Dashboard from "./Dashboard/Dashboard";
import SiteServices from "./SiteServices";
import Logo from "../../assets/images/arrival-logo.svg";

const Main = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className="main-content">
      {!isMobile && <ReactSVG className="header-logo" src={Logo} />}
      <Switch>
        <Route path="/Dashboard" component={Dashboard} />
        <Route path="/SiteServices" component={SiteServices} />
        <Redirect to="/Dashboard" />
      </Switch>
    </div>
  );
};

export default Main;
