import React from "react";

import Grid from "@material-ui/core/Grid";

const FilterBar = ({ children }) => (
  <div className="filter-bar">
    <Grid container spacing={3}>
      {Array.isArray(children) ? (
        children.map((child, index) => (
          <Grid key={index} item xs={12} md={2}>
            {child}
          </Grid>
        ))
      ) : (
        <Grid item xs={12} md={2}>
          {children}
        </Grid>
      )}
    </Grid>
  </div>
);

export default FilterBar;
