import React, { useEffect, useReducer } from "react";
import http from "../../../services/httpService";
import { apiEndpoint } from "../../../services/config";
import useDelete from "../../../hooks/delete";

import Header from "../../commons/Header";
import PageContent from "../../commons/PageContent";
import ActionBar from "../../commons/ActionBar";
import Table from "../../commons/table/Table";
import Button from "../../commons/button/Button";
import ServiceTypeDialog from "./ServiceTypeDialog/ServiceTypeDialog";
import Grid from "@material-ui/core/Grid";

const initialServiceTypeDialog = { isOpen: false, mode: "CREATE", serviceType: {} };

const serviceTypeDialogReducer = (state, action) => {
  switch (action.type) {
    case "OPEN":
      return {
        isOpen: true,
        mode: action.mode,
        serviceType: action.serviceType ? action.serviceType : {}
      };
    case "CLOSE":
      return initialServiceTypeDialog;
    default:
      throw new Error("Invalid action type.");
  }
};

const serviceTypesReducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.serviceTypes;
    case "ADD":
      return [...state, action.serviceType];
    case "REMOVE":
      return state.filter(serviceType => serviceType.id !== action.id);
    default:
      throw new Error("Invalid action type.");
  }
};

const ServiceTypes = () => {
  const [serviceTypes, dispatch] = useReducer(serviceTypesReducer, []);
  const [serviceTypeDialog, dispatchDialog] = useReducer(serviceTypeDialogReducer, initialServiceTypeDialog);
  const { handleDelete, renderDeleteDialog } = useDelete({
    api: `${apiEndpoint}/v1/outlet_service/service_type/`,
    callback: handleDeleteServiceType,
    customToast: { message: "Service type deleted.", type: "info" }
  });

  const columns = [
    {
      key: "color",
      className: "color-column",
      content: ({ color }) => <div className="color" style={{ backgroundColor: color }} />
    },
    { path: "name", label: "Name" },
    {
      key: "actions",
      className: "actions-column",
      content: serviceType => (
        <React.Fragment>
          <Button
            label="Edit"
            size="small"
            color="secondary"
            onClick={() => dispatchDialog({ type: "OPEN", mode: "EDIT", serviceType })}
          />
          <Button label="Delete" size="small" danger onClick={() => handleDelete(serviceType.id)} />
        </React.Fragment>
      )
    }
  ];

  useEffect(() => {
    let isCancelled = false;
    http
      .get(`${apiEndpoint}/v1/outlet_service/service_type/`)
      .then(({ data: serviceTypes }) => !isCancelled && dispatch({ type: "SET", serviceTypes }));
    return () => (isCancelled = true);
  }, []);

  function handleAddServiceType(serviceType) {
    dispatch({ type: "ADD", serviceType });
  }

  function handleEditServiceType(data) {
    let newServiceTypes = [...serviceTypes];
    newServiceTypes.forEach((serviceType, index) => {
      if (serviceType.id === data.id) newServiceTypes[index] = data;
    });
    dispatch({ type: "SET", serviceTypes: newServiceTypes });
  }

  function handleDeleteServiceType(id) {
    dispatch({ type: "REMOVE", id });
  }

  return (
    <React.Fragment>
      <Header title="Service Types" />
      <PageContent>
        <ActionBar>
          <Button label="Add" onClick={() => dispatchDialog({ type: "OPEN", mode: "CREATE" })} />
        </ActionBar>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={5}>
            <Table title="Service Types" columns={columns} data={serviceTypes} />
          </Grid>
        </Grid>
      </PageContent>
      <ServiceTypeDialog
        isOpen={serviceTypeDialog.isOpen}
        onClose={() => dispatchDialog({ type: "CLOSE" })}
        mode={serviceTypeDialog.mode}
        serviceType={serviceTypeDialog.serviceType}
        onAddServiceType={handleAddServiceType}
        onEditServiceType={handleEditServiceType}
      />
      {renderDeleteDialog()}
    </React.Fragment>
  );
};

export default ServiceTypes;
