import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context/auth-context";
import useForm from "../../../hooks/form";
import useSelectOptions from "../../../hooks/selectOptions";
import http from "../../../services/httpService";
import { apiEndpoint } from "../../../services/config";
import Joi from "joi-browser";
import moment from "moment";
import { toast } from "react-toastify";

import DialogHeader from "../../commons/dialog/DialogHeader";
import DialogContent from "../../commons/dialog/DialogContent";
import DialogActions from "../../commons/dialog/DialogActions";
import Detail from "../../commons/Detail";
import Select from "../../commons/input/Select";
import MultiSelect from "../../commons/input/MultiSelect";
import Button from "../../commons/button/Button";
import OutlinedButton from "../../commons/button/OutlinedButton";

const initialData = { company: "", site: "", serviceTypes: [] };

const schema = {
  company: Joi.number()
    .integer()
    .required(),
  site: Joi.number()
    .integer()
    .required(),
  serviceTypes: Joi.array()
    .min(1)
    .required()
};

const Create = ({ onClose, selectOptions, onAddSiteService }) => {
  const { data, handleSelectChange, validate } = useForm(initialData, schema);
  const [isLoading, setIsLoading] = useState(false);
  const { filterSitesByCompany } = useSelectOptions();
  const { user } = useContext(AuthContext);

  const handleSubmit = () => {
    setIsLoading(true);
    const formData = {
      service_date: moment().format("DD/MM/YYYY"),
      outlet_id: data.site,
      employee_ids: [user.id],
      service_type: data.serviceTypes
    };
    http.post(`${apiEndpoint}/v1/outlet_service/`, JSON.stringify(formData)).then(({ data }) => {
      onClose();
      toast("Site service created successfully.", { type: "success" });
      onAddSiteService(data);
      setIsLoading(false);
    });
  };

  return (
    <React.Fragment>
      <DialogHeader>Create Site Service</DialogHeader>
      <DialogContent>
        <Detail label="Date">{moment().format("DD/MM/YYYY")}</Detail>
        <Select
          label="Company"
          name="company"
          value={data["company"]}
          options={selectOptions["companies"]}
          onChange={handleSelectChange}
        />
        <Select
          label="Site"
          name="site"
          value={data["site"]}
          options={filterSitesByCompany(selectOptions["sites"], data["company"])}
          onChange={handleSelectChange}
          disabled={data["company"] ? false : true}
        />
        <MultiSelect
          label="Service Type(s)"
          name="serviceTypes"
          value={data["serviceTypes"]}
          options={selectOptions["serviceTypes"]}
          onChange={handleSelectChange}
        />
      </DialogContent>
      <DialogActions>
        <OutlinedButton label="Cancel" onClick={onClose} />
        <Button label="Create" onClick={handleSubmit} isLoading={isLoading} validate={validate()} />
      </DialogActions>
    </React.Fragment>
  );
};

export default Create;
