import React, { useEffect, useState } from "react";
import { apiEndpoint } from "../../../services/config";
import http from "../../../services/httpService";
import useDelete from "../../../hooks/delete";

import Header from "../../commons/Header";
import PageContent from "../../commons/PageContent";
import ActionBar from "../../commons/ActionBar";
import Button from "../../commons/button/Button";
import Table from "../../commons/table/Table";
import EmployeeDialog from "./EmployeeDialog";
import ReactAvatar from "react-avatar";
import Grid from "@material-ui/core/Grid";

const Employees = () => {
  const [employees, setEmployees] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { handleDelete, renderDeleteDialog } = useDelete({
    api: `${apiEndpoint}/v1/staff/`,
    callback: handleRemoveEmployee
  });

  const columns = [
    {
      path: "name",
      label: "Name",
      content: employee => (
        <React.Fragment>
          <ReactAvatar
            className="avatar"
            name={employee.image ? null : employee.name}
            src={employee.image ? employee.image : null}
            round
            size="40"
          />
          {employee.name}
        </React.Fragment>
      )
    },
    {
      key: "actions",
      className: "actions-column",
      content: ({ uuid }) => <Button label="Delete" danger size="small" onClick={() => handleDelete(uuid)} />
    }
  ];

  useEffect(() => {
    let isCancelled = false;
    http.get(`${apiEndpoint}/v1/staff`).then(({ data }) => !isCancelled && setEmployees(data));
    return () => (isCancelled = true);
  }, []);

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleAddEmployee = employee => {
    setEmployees(employees => [...employees, employee]);
  };

  function handleRemoveEmployee(uuid) {
    setEmployees(employees.filter(employee => employee.uuid !== uuid));
  }

  return (
    <React.Fragment>
      <Header title="Employees" />
      <PageContent>
        <ActionBar>
          <Button label="Add" onClick={toggleDialog} />
        </ActionBar>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Table title="Employees" columns={columns} data={employees} />
          </Grid>
        </Grid>
      </PageContent>
      <EmployeeDialog isOpen={isDialogOpen} onClose={toggleDialog} onAddEmployee={handleAddEmployee} />
      {renderDeleteDialog()}
    </React.Fragment>
  );
};

export default Employees;
