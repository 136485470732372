import React from "react";

import { default as MuiButton } from "@material-ui/core/Button";

const TextButton = ({ label, color = "primary", onClick, disabled = false, size = "medium", fullWidth }) => (
  <MuiButton variant="text" color={color} onClick={onClick} disabled={disabled} size={size} fullWidth={fullWidth}>
    {label}
  </MuiButton>
);

export default TextButton;
