import React from "react";

import MomentUtils from "@date-io/moment";
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const DatePicker = ({ label, name, value, views, minDate, onChange }) => (
  <div className="input">
    <div className="input__label">{label}</div>
    <div className="input__field">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiDatePicker
          views={views}
          inputVariant="outlined"
          value={value}
          minDate={minDate}
          onChange={onChange && onChange(name)}
          fullWidth
        />
      </MuiPickersUtilsProvider>
    </div>
  </div>
);

export default DatePicker;
