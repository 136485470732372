import React, { useEffect, useState, useReducer } from "react";
import { apiEndpoint } from "../../services/config";
import http from "../../services/httpService";
import useSelectOptions from "../../hooks/selectOptions";
import useFilter from "../../hooks/filter";
import moment from "moment";
import qs from 'qs';

import Calendar from "../commons/Calendar";
import SiteServiceDialog from "./SiteServices/SiteServiceDialog/SiteServiceDialog";
import ExportDialog from "./ExportDialog";
import Header from "../commons/Header";
import ActionBar from "../commons/ActionBar";
import FilterBar from "../commons/FilterBar";
import Select from "../commons/input/Select";
import MultiSelect from "../commons/input/MultiSelect";
import PageContent from "../commons/PageContent";
import Button from "@material-ui/core/Button";

const initialSiteServiceDialog = { isOpen: false, mode: "CREATE", date: "", siteService: {} };

const filterData = {
  month: { value: moment().month() + 1, indicator: "month" },
  year: { value: moment().year(), indicator: "year" },
  company: { value: "", indicator: "id" },
  employees: { value: [], indicator: "id" }
};

const siteServicesReducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.siteServices;
    case "ADD":
      return [...state, action.siteService];
    case "REMOVE":
      return state.filter(siteService => siteService.uuid !== action.uuid);
    default:
      throw new Error("Invalid action type.");
  }
};

const siteServiceDialogReducer = (state, { type, mode, date, siteService }) => {
  switch (type) {
    case "OPEN":
      return { isOpen: true, mode, date: date || "", siteService: siteService || {} };
    case "CLOSE":
      return { ...state, isOpen: false };
    case "RESET":
      return initialSiteServiceDialog;
    default:
      throw new Error("Invalid action type.");
  }
};

const Dashboard = () => {
  const [siteServices, dispatchSS] = useReducer(siteServicesReducer, []);
  const [siteServiceDialog, dispatchSSDialog] = useReducer(siteServiceDialogReducer, initialSiteServiceDialog);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const { selectOptions, setSelectOptions, getOptionsBy } = useSelectOptions();
  const [isLoading, setIsLoading] = useState(false);
  const { filter, filtered, handleSelectChange, handleDeleteChip, handleCalendarChange } = useFilter(
    filterData,
    siteServices
  );

  useEffect(() => {
    let isCancelled = false;
    getOptionsBy(["COMPANY", "SITE", "EMPLOYEE", "SERVICE_TYPE"]).then(
      options => !isCancelled && setSelectOptions(options)
    );
    return () => (isCancelled = true);
  }, []);

  useEffect(() => {
    let isCancelled = false;
    const params = {};
    for (const key in filter) {
      if(typeof filter[key].value === "array" || typeof filter[key].value === "object" ) {
        if(filter[key].value.length !== 0) params[key] = filter[key].value;
      } else {
        if(filter[key].value) params[key] = filter[key].value;
      }
    }
    if(!isCancelled) {
      setIsLoading(true);
      http.get(`${apiEndpoint}/v1/outlet_service/`, { params, paramsSerializer: params => qs.stringify(params, {arrayFormat: 'comma'}) })
      .then(({ data: siteServices }) => {
        siteServices.forEach(
          siteService => (siteService.start = siteService.end = moment(siteService.service_date, "DD/MM/YYYY"))
        );
        setIsLoading(false);
        dispatchSS({ type: "SET", siteServices });
      });
    }
    return () => (isCancelled = true);
  }, [filter]);

  const handleSlotSelect = ({ start: date }) => dispatchSSDialog({ type: "OPEN", mode: "CREATE", date });

  const handleEventSelect = siteService => dispatchSSDialog({ type: "OPEN", mode: "VIEW", siteService });

  const toggleEditMode = () => {
    let mode = siteServiceDialog.mode === "EDIT" ? "VIEW" : "EDIT";
    dispatchSSDialog({ type: "OPEN", mode, siteService: siteServiceDialog.siteService });
  };

  function handleAddSiteService(data) {
    data["start"] = data["end"] = moment(data.service_date, "DD/MM/YYYY");
    dispatchSS({ type: "ADD", siteService: data });
  }

  function handleChangeSiteService(data) {
    let newSiteServices = [...siteServices];
    newSiteServices.forEach((siteService, index) => {
      if (siteService.id === data.id) {
        data.start = data.end = moment(data.service_date, "DD/MM/YYYY");
        newSiteServices[index] = data;
      }
    });
    dispatchSS({ type: "SET", siteServices: newSiteServices });
  }

  function handleRemoveSiteService(uuid) {
    dispatchSS({ type: "REMOVE", uuid });
  }

  return (
    <React.Fragment>
      <Header title="Dashboard" />
      <PageContent>
        <ActionBar>
          <Button variant="contained" color="primary" onClick={() => setIsExportDialogOpen(!isExportDialogOpen)}>
            Export
          </Button>
        </ActionBar>
        <FilterBar>
          <Select
            label="Company"
            name="company"
            value={filter.company.value}
            options={selectOptions["companies"]}
            onChange={handleSelectChange}
          />
          <MultiSelect
            label="Employee(s)"
            name="employees"
            value={filter.employees.value}
            options={selectOptions["employees"]}
            onChange={handleSelectChange}
            onDeleteChip={handleDeleteChip}
          />
        </FilterBar>
        <Calendar
          events={filtered}
          onSelectSlot={handleSlotSelect}
          onSelectEvent={handleEventSelect}
          onNavigate={(date) => handleCalendarChange(date)}
          isLoading={isLoading}
        />
        <SiteServiceDialog
          isOpen={siteServiceDialog.isOpen}
          onClose={() => dispatchSSDialog({ type: "CLOSE" })}
          onExited={() => dispatchSSDialog({ type: "RESET" })}
          date={siteServiceDialog.date}
          mode={siteServiceDialog.mode}
          siteService={siteServiceDialog.siteService}
          selectOptions={selectOptions}
          onToggleEditMode={toggleEditMode}
          onCancelEdit={toggleEditMode}
          onAddSiteService={handleAddSiteService}
          onChangeSiteService={handleChangeSiteService}
          onRemoveSiteService={handleRemoveSiteService}
        />
        <ExportDialog isOpen={isExportDialogOpen} onClose={() => setIsExportDialogOpen(!isExportDialogOpen)} />
      </PageContent>
    </React.Fragment>
  );
};

export default Dashboard;
