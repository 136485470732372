import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/auth-context";
import http from "../../services/httpService";
import { apiEndpoint } from "../../services/config";
import _ from "lodash";
import moment from "moment";

import Header from "../commons/Header";
import PageContent from "../commons/PageContent";
import Table from "../commons/table/Table";
import NoSiteService from "../commons/NoSiteService";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

const SiteServices = () => {
  const { user } = useContext(AuthContext);
  const [siteServices, setSiteServices] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    http.get(`${apiEndpoint}/v1/outlet_service/`).then(({ data }) => {
      if (!isCancelled) setSiteServices(data);
    });
    return () => (isCancelled = true);
  }, []);

  const completedSSTableColumns = [
    { path: "company.name", label: "Company" },
    { path: "outlet.name", label: "Site" },
    {
      path: "service_type_details",
      className: "service_type-column",
      label: "Service Type",
      content: ({ service_type_details }) => (
        <Grid container spacing={1}>
          {service_type_details.map(service_type => (
            <Grid key={service_type.id} item>
              <Chip label={service_type.name} size="small" />
            </Grid>
          ))}
        </Grid>
      )
    },
    {
      path: "clock_in",
      label: "Clock In",
      content: ({ attendance }) => getClockRecord(attendance, "clock_in")
    },
    {
      path: "clock_out",
      label: "Clock Out",
      content: ({ attendance }) => getClockRecord(attendance, "clock_out")
    },
    { path: "service_date", label: "Date" }
  ];

  const upcomingSSTableColumns = [
    { path: "company.name", label: "Company" },
    { path: "outlet.name", label: "Outlet" },
    {
      path: "service_type_details",
      className: "service_type-column",
      label: "Service Type",
      content: ({ service_type_details }) => (
        <Grid container spacing={1}>
          {service_type_details.map(service_type => (
            <Grid key={service_type.id} item>
              <Chip label={service_type.name} size="small" />
            </Grid>
          ))}
        </Grid>
      )
    },
    { path: "service_date", label: "Date" }
  ];

  const getCompletedSiteServices = () => {
    let completedSiteServices = siteServices.filter(site_service =>
      site_service.attendance.find(att => att.employee === user.id && att.status === "COMPLETED")
    );
    completedSiteServices.sort((a, b) => {
      return (
        new Date(b.attendance.find(att => att.employee === user.id && att.status === "COMPLETED").clock_out) -
        new Date(a.attendance.find(att => att.employee === user.id && att.status === "COMPLETED").clock_out)
      );
    });
    return completedSiteServices;
  };

  const getUpcomingSiteServices = () =>
    siteServices.filter(site_service => moment(site_service.service_date, "DD/MM/YYYY") > moment());

  const getClockRecord = (data, action) => {
    const attendance = data.find(att => att.employee === user.id);
    return moment(attendance[action]).format("LT");
  };

  return (
    <React.Fragment>
      <Header title="Site Services" />
      <PageContent>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={7}>
            {_.size(getCompletedSiteServices()) > 0 ? (
              <Table
                title="Completed Site Service"
                columns={completedSSTableColumns}
                data={getCompletedSiteServices()}
              />
            ) : (
              <NoSiteService content="You don't have any completed site service." />
            )}
          </Grid>
          <Grid item xs={12} lg={5}>
            {_.size(getUpcomingSiteServices()) > 0 ? (
              <Table title="Upcoming Site Service" columns={upcomingSSTableColumns} data={getUpcomingSiteServices()} />
            ) : (
              <NoSiteService content="There are no upcoming site service." />
            )}
          </Grid>
        </Grid>
      </PageContent>
    </React.Fragment>
  );
};

export default SiteServices;
