import React, { useState } from "react";
import useForm from "../../../hooks/form";
import http from "../../../services/httpService";
import { apiEndpoint } from "../../../services/config";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import Dialog from "../../commons/dialog/Dialog";
import DialogHeader from "../../commons/dialog/DialogHeader";
import DialogContent from "../../commons/dialog/DialogContent";
import DialogAction from "../../commons/dialog/DialogActions";
import TextField from "../../commons/input/TextField";
import Button from "../../commons/button/Button";
import OutlinedButton from "../../commons/button/OutlinedButton";

const initialData = { name: "", email: "", password: "" };

const schema = {
  name: Joi.string()
    .required()
    .trim()
    .label("Name")
    .error(() => {
      return { message: "Name is required." };
    }),
  email: Joi.string()
    .email({ minDomainAtoms: 2, tldWhitelist: ["com"] })
    .required()
    .label("Email")
    .error(errors => {
      if (errors[0].context.value === "") return { message: "Your email is required." };
      return { message: "Your email must be valid." };
    }),
  password: Joi.string()
    .required()
    .label("Password")
    .error(() => {
      return { message: "Password is required." };
    })
};

const EmployeeDialog = ({ isOpen, onClose, onAddEmployee }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { data, errors, handleTextFieldChange, validate, resetData } = useForm(initialData, schema);

  const handleSubmit = () => {
    setIsLoading(true);
    http.post(`${apiEndpoint}/v1/staff/`, JSON.stringify(data)).then(({ data }) => {
      toast(`${data.name} created.`, { type: "success" });
      onClose();
      onAddEmployee(data);
    });
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} onExited={resetData} size="xs">
      <DialogHeader>Create Employee</DialogHeader>
      <DialogContent>
        <TextField
          label="Name"
          name="name"
          value={data["name"]}
          errors={errors["name"]}
          onChange={handleTextFieldChange}
        />
        <TextField
          label="Email"
          name="email"
          value={data["email"]}
          errors={errors["email"]}
          onChange={handleTextFieldChange}
        />
        <TextField
          label="Password"
          name="password"
          value={data["password"]}
          errors={errors["password"]}
          onChange={handleTextFieldChange}
          type="password"
        />
        {/* <div className="checkbox">
          <div className="checkbox__label">This person is an admin.</div>
          <div className="checkbox__field">
            <Checkbox
              checked={isAdmin}
              color="primary"
              onChange={handleIsAdminChange}
              value="isAdmin"
            />
          </div>
        </div> */}
      </DialogContent>
      <DialogAction>
        <OutlinedButton label="Cancel" onClick={onClose} />
        <Button label="Create" isLoading={isLoading} validate={validate()} onClick={handleSubmit} />
      </DialogAction>
    </Dialog>
  );
};

export default EmployeeDialog;
