import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ReactSVG } from "react-svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import Dashboard from "./Dashboard";
import SiteServices from "./SiteServices/SiteServices";
import Employees from "./Employees/Employees";
import Company from "./Company/Company";
import Sites from "./Site/Sites";
import ServiceTypes from "./ServiceTypes/ServiceTypes";
import Logo from "../../assets/images/arrival-logo.svg";

const Main = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className="main-content">
      {!isMobile && <ReactSVG className="header-logo" src={Logo} />}
      <Switch>
        <Route path="/Dashboard" component={Dashboard} />
        <Route path="/SiteServices" component={SiteServices} />
        <Route path="/Employees" component={Employees} />
        <Route path="/Company" component={Company} />
        <Route path="/Sites" component={Sites} />
        <Route path="/ServiceTypes" component={ServiceTypes} />
        <Redirect to="/Dashboard" />
      </Switch>
    </div>
  );
};

export default Main;
