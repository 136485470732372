import React, { useState } from "react";
import http from "../../../../services/httpService";
import { apiEndpoint } from "../../../../services/config";
import useForm from "../../../../hooks/form";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import DialogHeader from "../../../commons/dialog/DialogHeader";
import DialogContent from "../../../commons/dialog/DialogContent";
import DialogActions from "../../../commons/dialog/DialogActions";
import TextField from "../../../commons/input/TextField";
import ColorPicker from "../../../commons/input/ColorPicker";
import Button from "../../../commons/button/Button";
import OutlinedButton from "../../../commons/button/OutlinedButton";

const initialData = { name: "", color: "#e0e0e0" };

const schema = {
  name: Joi.string()
    .required()
    .trim()
    .error(() => {
      return { message: "Name is required." };
    }),
  color: Joi.string().optional()
};

const Create = ({ onClose, onAddServiceType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { data, errors, handleTextFieldChange, handleColorChange, validate } = useForm(initialData, schema);

  const handleSubmit = () => {
    setIsLoading(true);
    http.post(`${apiEndpoint}/v1/outlet_service/service_type/`, JSON.stringify(data)).then(({ data }) => {
      toast(`${data.name} created.`, { type: "success" });
      onClose();
      onAddServiceType(data);
    });
  };

  return (
    <React.Fragment>
      <DialogHeader>Create Service Type</DialogHeader>
      <DialogContent>
        <TextField
          label="Name"
          name="name"
          value={data["name"]}
          errors={errors["name"]}
          onChange={handleTextFieldChange}
        />
        <ColorPicker label="Color" value={data["color"]} onChange={handleColorChange} />
      </DialogContent>
      <DialogActions>
        <OutlinedButton label="Cancel" onClick={onClose} />
        <Button label="Create" isLoading={isLoading} validate={validate()} onClick={handleSubmit} />
      </DialogActions>
    </React.Fragment>
  );
};

export default Create;
