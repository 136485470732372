import React, { useState, useEffect } from "react";
import http from "../../../../services/httpService";
import { apiEndpoint } from "../../../../services/config";
import useDelete from "../../../../hooks/delete";
import _ from "lodash";

import DialogHeader from "../../../commons/dialog/DialogHeader";
import DialogContent from "../../../commons/dialog/DialogContent";
import DialogActions from "../../../commons/dialog/DialogActions";
import Detail from "../../../commons/Detail";
import Button from "../../../commons/button/Button";
import OutlinedButton from "../../../commons/button/OutlinedButton";
import { useTheme, Grid, Chip, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const View = ({ onClose, siteService, onToggleEditMode, onRemoveSiteService }) => {
  const { palette } = useTheme();
  const [serviceTypesColor, setServiceTypesColor] = useState({});
  const { handleDelete, renderDeleteDialog } = useDelete({
    api: `${apiEndpoint}/v1/outlet_service/`,
    callback: handleDeleteCallback,
    customToast: { message: "Site service has been deleted.", type: "info" }
  });

  useEffect(() => {
    let isCancelled = false;
    http.get(`${apiEndpoint}/v1/outlet_service/service_type/`).then(({ data: serviceTypes }) => {
      if (!isCancelled) {
        let colors = {};
        serviceTypes.forEach(serviceType => (colors[serviceType.id] = serviceType.color));
        setServiceTypesColor(colors);
      }
    });
    return () => (isCancelled = true);
  }, []);

  const renderServiceTypeChip = serviceType => {
    const ServiceTypeChip = withStyles({
      root: { backgroundColor: serviceTypesColor[serviceType.id] },
      label: { color: palette.getContrastText(serviceTypesColor[serviceType.id]) }
    })(Chip);
    return <ServiceTypeChip label={serviceType.name} />;
  };

  const renderEmployeeChip = employee => {
    const isCompleted = getCompletionStatus(employee.id);
    const EmployeeChip = withStyles({
      root: { backgroundColor: isCompleted === true && "#a3f100" }
    })(Chip);
    return isCompleted ? (
      <Tooltip title="This employee has completed this site service." arrow>
        <EmployeeChip label={employee.name} />
      </Tooltip>
    ) : (
      <EmployeeChip label={employee.name} />
    );
  };

  const getCompletionStatus = employeeId => {
    if (_.size(siteService.attendance) > 0) {
      const attendance = siteService.attendance.find(att => att.employee === employeeId);
      if (attendance === undefined) return false;
      if (attendance.status === "COMPLETED") return true;
      else return false;
    } else return false;
  };

  function handleDeleteCallback(uuid) {
    onClose();
    onRemoveSiteService(uuid);
  }

  return (
    <React.Fragment>
      <DialogHeader>{siteService.title}</DialogHeader>
      <DialogContent>
        <Detail label="Created by">{siteService.created_by.employee.name}</Detail>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Detail label="Company">{siteService.company.name}</Detail>
          </Grid>
          <Grid item xs={12} md={6}>
            <Detail label="Site">{siteService.outlet.name}</Detail>
          </Grid>
        </Grid>
        <Detail label="Service Type(s)">
          <Grid container spacing={1}>
            {siteService.service_type_details.map(serviceType => (
              <Grid item key={serviceType.id}>
                {!_.isEmpty(serviceTypesColor) && renderServiceTypeChip(serviceType)}
              </Grid>
            ))}
          </Grid>
        </Detail>
        <Detail label="Employee(s)">
          <Grid container spacing={1}>
            {siteService.employees.map(employee => (
              <Grid item key={employee.id}>
                {renderEmployeeChip(employee)}
              </Grid>
            ))}
          </Grid>
        </Detail>
      </DialogContent>
      <DialogActions>
        <OutlinedButton label="Close" onClick={onClose} />
        <Button label="Delete" danger onClick={() => handleDelete(siteService.uuid)} />
        <Button label="Edit" color="secondary" onClick={onToggleEditMode} />
      </DialogActions>
      {renderDeleteDialog()}
    </React.Fragment>
  );
};

export default View;
